import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./app/screens/Login";
import NotFound from "./app/screens/NotFound";
import Prueba from "./app/screens/prueba";

//admin
import AdminDashboard from "./app/screens/AdminDashboard";
import AdminDirectorioAdd from "./app/screens/AdminDirectorio/addUsers";
import AdminDirectorioAddStaff from "./app/screens/AdminDirectorio/addStaff";
import AdminPagosSearch from "./app/screens/AdminPagos/searchStudents";
import AdminPagosDetail from "./app/screens/AdminPagos/detail";
import AdminDashboardDerechos from "./app/screens/AdminDerechos";
import AdminMontos from "./app/screens/AdminMontos";
import AdminMontosDetail from "./app/screens/AdminMontos/detail";
import AdminDerechosDetail from "./app/screens/AdminDerechos/detail";
import AdminDashboardServicioBecario from "./app/screens/AdminServicioBecario";
import AdminServicioBecarioDetail from "./app/screens/AdminServicioBecario/detail";
import AdminDirectorio from "./app/screens/AdminDirectorio";
import AdminGeneradorPdf from "./app/screens/AdminGeneradorPdf";
import Birthday from "./app/screens/Birthday";

//alumnos
import ServicioBecario from "./app/screens/ServicioBecario/detail";
import PagosDetail from "./app/screens/Pagos/detail";
import PagosSemestres from "./app/screens/Pagos/semesters";
import Dashboard from "./app/screens/Dashboard";

import jwt_decode from "jwt-decode";
import { reloadLogin } from "./app/features/auth/authSlice";
import { useDispatch } from "react-redux";

const BRouter = () => {
  const dispatch = useDispatch();

  const isAuthenticated = async (role) => {
    const token = localStorage.getItem("token");

    if (!token) {
      return false;
    }

    const decodedToken = jwt_decode(token);
    const types = JSON.parse(decodedToken.sub.type);
    const keysTypes = Object.keys(types);
    let admitRole;

    for (let index = 0; index < role.length; index++) {
      for (let j = 0; j < keysTypes.length; j++) {
        if (role[index] === keysTypes[j]) {
          if (types[keysTypes[j]] === true) {
            admitRole = keysTypes[j];
          }
        }
      }
    }

    if (!admitRole) {
      localStorage.removeItem("token");
      await dispatch(reloadLogin());
      return false;
    }

    return admitRole;
  };

  const PrivateRoute = ({ component: Component, role, ...rest }) => {
    return isAuthenticated(role) ? <Component /> : <Navigate to="/" replace />;
  };

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/401" element={<Login />} />
        <Route path="*" element={<NotFound />} />
        <Route
          exact
          path="/alumnos"
          element={<PrivateRoute component={Dashboard} role={["dashboard"]} />}
        />
        <Route
          exact
          path="/alumnos/pagos/:idAlumno"
          element={<PrivateRoute component={PagosSemestres} role={["pagos"]} />}
        />
        <Route
          exact
          path="/alumnos/pagos/:idAlumno/:idSemestre"
          element={<PrivateRoute component={PagosDetail} role={["pagos"]} />}
        />
        <Route
          exact
          path="/alumnos/servicio-becario/:idAlumno"
          element={
            <PrivateRoute
              component={ServicioBecario}
              role={["servicioBecario"]}
            />
          }
        />
        <Route exact path="/prueba" element={<Prueba />} />

        <Route
          exact
          path="/admin"
          element={
            <PrivateRoute
              component={AdminDashboard}
              role={["adminDashboard"]}
            />
          }
        />
        <Route
          exact
          path="/admin/pagos"
          element={
            <PrivateRoute component={AdminPagosSearch} role={["adminPagos"]} />
          }
        />
        <Route
          exact
          path="/admin/pagos/:idAlumno"
          element={
            <PrivateRoute component={AdminPagosDetail} role={["adminPagos"]} />
          }
        />
        <Route
          exact
          path="/admin/montos"
          element={
            <PrivateRoute component={AdminMontos} role={["adminPagos"]} />
          }
        />
        <Route
          exact
          path="/admin/montos/:idExternalUser"
          element={
            <PrivateRoute component={AdminMontosDetail} role={["adminPagos"]} />
          }
        />

        <Route
          exact
          path="/admin/directorio"
          element={
            <PrivateRoute
              component={AdminDirectorio}
              role={["adminDirectorio"]}
            />
          }
        />
        <Route
          exact
          path="/admin/directorio/alumno/:idUser"
          element={
            <PrivateRoute
              component={AdminDirectorioAdd}
              role={["adminDirectorio"]}
            />
          }
        />
        <Route
          exact
          path="/admin/directorio/alumno/agregar"
          element={
            <PrivateRoute
              component={AdminDirectorioAdd}
              role={["adminDirectorio"]}
            />
          }
        />
        <Route
          exact
          path="/admin/directorio/agregar"
          element={
            <PrivateRoute
              component={AdminDirectorioAddStaff}
              role={["adminDirectorio"]}
            />
          }
        />
        <Route
          exact
          path="/admin/directorio/:idUser"
          element={
            <PrivateRoute
              component={AdminDirectorioAddStaff}
              role={["adminDirectorio"]}
            />
          }
        />
        <Route
          exact
          path="/admin/derechos"
          element={
            <PrivateRoute
              component={AdminDashboardDerechos}
              role={["adminDerechos"]}
            />
          }
        />
        <Route
          exact
          path="/admin/derechos/:idExtrernalUser"
          element={
            <PrivateRoute
              component={AdminDerechosDetail}
              role={["adminDerechos"]}
            />
          }
        />
        <Route
          exact
          path="/admin/servicio-becario"
          element={
            <PrivateRoute
              component={AdminDashboardServicioBecario}
              role={["adminServicioBecario"]}
            />
          }
        />
        <Route
          exact
          path="/admin/servicio-becario/:idExtrernalUser"
          element={
            <PrivateRoute
              component={AdminServicioBecarioDetail}
              role={["adminServicioBecario"]}
            />
          }
        />
        <Route
          exact
          path="/admin/generador-pdf"
          element={
            <PrivateRoute component={AdminGeneradorPdf} role={["adminPdfs"]} />
          }
        />
        <Route
          exact
          path="/admin/birthday"
          element={<PrivateRoute component={Birthday} role={["adminBirthday"]} />}
        />
      </Routes>
    </Router>
  );
};

export default BRouter;
