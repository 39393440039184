import { WidthFull } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';

const SelectAutocomplete = ({ options,setSelectedOption, label}) => {

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option ? option.label : ''}
      onChange={(event, newValue) => setSelectedOption(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
        />
      )}
    />
  );
};

export default SelectAutocomplete;
