import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  data: [
    {
      id: "",
      description: "",
      date: "",
      amount: 0,
      voucher: "",
      idExternalUser: "",
      xml: "",
    },
  ],
  isLoading: false,
  error: [
    {
      message: "",
      code: "",
    },
  ],
};

export const getAllPayments = createAsyncThunk(
  "payments/getAllPayments",
  async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.get(
        process.env.REACT_APP_API_CORE_IMC + "/payments/getAll",
        { headers: headers }
      );
      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const getPaymentsByIdExternalUserAndCycle = createAsyncThunk(
  "payments/getPaymentsByIdExternalUserAndCycle",
  async ({ idExternalUser, cycle }) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };

      const request = {
        idExternalUser,
        cycle,
      };

      const response = await axios.post(
        process.env.REACT_APP_API_CORE_IMC +
          "/payments/getPaymentsByIdExternalUserAndCycle",
        request,
        { headers: headers }
      );
      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const getPaymentsByIdExternalUser = createAsyncThunk(
  "payments/getPaymentsByIdExternalUser",
  async (idExternalUser) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.get(
        process.env.REACT_APP_API_CORE_IMC +
          "/payments/getPaymentsByUser/" +
          idExternalUser,
        { headers: headers }
      );
      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const insertPayment = createAsyncThunk(
  "payments/insertPayment",
  async (formData) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_CORE_IMC + "/payments/insertPayment",
        formData,
        config
      );
      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const deletePayment = createAsyncThunk(
  "payments/deletePayment",
  async ({ idPayment, idUser }) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.post(
        process.env.REACT_APP_API_CORE_IMC + "/payments/delete",
        { idPayment: idPayment, idUser: idUser },
        { headers: headers }
      );
      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const paymentsSlice = createSlice({
  name: "payments",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPayments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPayments.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })

      .addCase(getAllPayments.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getPaymentsByIdExternalUserAndCycle.pending, (state) => {
        state.isLoading = true;
        state.data = [];
      })

      .addCase(
        getPaymentsByIdExternalUserAndCycle.fulfilled,
        (state, action) => {
          state.data = action.payload.data.data;
          state.isLoading = false;
        }
      )
      .addCase(getPaymentsByIdExternalUserAndCycle.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(insertPayment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(insertPayment.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(insertPayment.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getPaymentsByIdExternalUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPaymentsByIdExternalUser.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.isLoading = false;
      })
      .addCase(getPaymentsByIdExternalUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deletePayment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePayment.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deletePayment.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default paymentsSlice.reducer;
