import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import unorm from "unorm";

const initialState = {
  data: [
    {
      id: "",
      idExternalUser: "",
      titleCourtesy: "",
      name: "",
      secondName: "",
      lastName1: "",
      lastName2: "",
      gender: "",
      phone: 0,
      cp: 0,
      career: "",
      idExternalUserStudent: "",
      active: 0,
      created_at: "",
      updated_at: "",
    },
  ],
  isLoading: false,
  filter: [],
  error: [
    {
      message: "",
      code: "",
    },
  ],
};

export const getTutorsAsync = createAsyncThunk(
  "tutors/getTutorsAsync",
  async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.get(
        process.env.REACT_APP_API_CORE_IMC + "/tutors/getAllActive",
        { headers: headers }
      );

      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const tutorsSlice = createSlice({
  name: "tutors",
  initialState: initialState,
  reducers: {
    filterTutors: (state, action) => {
      const { payload } = action;
      const { data } = state;
    
      // Función para normalizar y convertir a minúsculas
      const normalizeAndLowercase = (str) => unorm.nfkd(str).toLowerCase();
    
      if (payload !== "") {
        const filteredData = data.filter((tutor) => {
          const fullName = `${tutor.name} ${tutor.secondName || ""} ${tutor.lastName1} ${tutor.lastName2}`;
          return (
           
              normalizeAndLowercase(tutor.name).includes(normalizeAndLowercase(payload)) ||
              normalizeAndLowercase(tutor.lastName1).includes(normalizeAndLowercase(payload)) ||
              normalizeAndLowercase(tutor.lastName2).includes(normalizeAndLowercase(payload)) ||
              normalizeAndLowercase(fullName).includes(normalizeAndLowercase(payload))
            
          );
        });
    
        state.filter = filteredData;
      } else {
      
          state.filter = data;

      }
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTutorsAsync.pending, (state) => {
        state.error = [];
        state.isLoading = true;
      })
      .addCase(getTutorsAsync.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.filter = action.payload.data;
        state.error = [];
        state.isLoading = false;
      })
      .addCase(getTutorsAsync.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { filterTutors } = tutorsSlice.actions;

export default tutorsSlice.reducer;
