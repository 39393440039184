import * as React from "react";
import TaskForm from "../../components/pruebas/taskForm"
import TaskList from "../../components/pruebas/taskList"

export default function Prueba() {

  return (
<div>
    <h1>Hola</h1>
    <TaskForm/>
    <TaskList/>
</div>
  );
}
