import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import NavBar from "../../components/NavBar";
import Grid from "@mui/material/Unstable_Grid2";
import logo from '../../assets/imc-logo.png';

function NotFound() {

  return (
    <Container>
      <NavBar />
      <Box>
        <Box sx={{ mb: 3 }}>
          <Typography sx={{ fontWeight: "bold",color:"red", fontSize: { xs: 28, md: 36 } }}>
          ¡Error 404: Página no encontrada!
          </Typography>
          <Box sx={{ flexGrow: 1, mt: 5 }}>
            <Grid container spacing={2}>
              <Grid xs={12} md={2}>
              </Grid>
              <Grid xs={12} md={8}>

              <img src={logo} alt="logo" width={"60%"}/>

              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default NotFound;

