import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  data: [
    {
      id:"",
      name:"",
      description:"",
    },
  ],
  isLoading: false,
  filter: [],
  error: [
    {
      message: "",
      code: "",
    },
  ],
};

export const getCatalogByType = createAsyncThunk(
  "catalogs/getCatalogByType",
  async (type) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.get(
        process.env.REACT_APP_API_CORE_IMC + "/catalogs/getCatalogByType/"+type,
        { headers: headers }
      );

      return response.data.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const catalogsSlice = createSlice({
  name: "catalogs",
  initialState: initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      .addCase(getCatalogByType.pending, (state) => {
        state.error = [];
        state.isLoading = true;
      })
      .addCase(getCatalogByType.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.filter = action.payload.data;
        state.error = [];
        state.isLoading = false;
      })
      .addCase(getCatalogByType.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});


export default catalogsSlice.reducer;
