import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { useLocation, useParams } from 'react-router-dom';

export default function CustomizedTables(data) {
  let rows = data.data;
  let headers = data.headers;
  const admin=data.admin
  const history = useNavigate();
  const location = useLocation();
  const path = window.location.pathname;


  const defaultStyleTableCell = {
    borderBottom: "none",
    marginTop: 3,
    padding: "17px 0 17px 0",
    fontSize: 16,
  };

  const titleCell = {
    borderBottom: "none",
    padding: `0px 2px`,
    lineHeight: "0.9rem",
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: 20,
  };

  const tableCellFirst = {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    borderLeftColor: "#2e7d32",
    borderLeftStyle: "solid",
    borderLeftWidth: 12,
    padding: 0,
  };

  const tableCellFirstRed = {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    borderLeftColor: "#d32f2f",
    borderLeftStyle: "solid",
    borderLeftWidth: 12,
    padding: 0,
  };


  return (
    <TableContainer>
      <Table
        stickyHeader={true}
        sx={{ width: "99.9%", borderSpacing: `0 20px` }}
        aria-label="customized table"
      >
        <TableHead>
          {headers.map((header) => (
            <TableRow key={header.id}>
              <TableCell sx={{ ...titleCell }} align="center">
                {header.title1}
              </TableCell>
              <TableCell sx={{ ...titleCell }} align="center">
                {header.title2}
              </TableCell>
              <TableCell sx={{ ...titleCell }} align="center">
                {header.title3}
              </TableCell>
              <TableCell sx={{ ...titleCell }} align="center">
                {header.title4}
              </TableCell>
              <TableCell sx={{ ...titleCell }} align="center">
                {header.title5}
              </TableCell>
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              onClick={() => history(path+"/"+row.id)}
              sx={{ borderRadius: 3, boxShadow: 4 }}
            >
              <TableCell sx={{ ...defaultStyleTableCell,...(row.data5 === 'Pendiente' ? tableCellFirstRed : tableCellFirst) }} align="center">
                {row.data1}
              </TableCell>
              <TableCell sx={{ ...defaultStyleTableCell }} align="center">
                {row.data2}
              </TableCell>
              <TableCell sx={{ ...defaultStyleTableCell }} align="center">
                {row.data3}
              </TableCell>
              <TableCell sx={{ ...defaultStyleTableCell }} align="center">
                {row.data4}
              </TableCell>
              <TableCell sx={{ ...defaultStyleTableCell }} align="center">
                <Chip
                  label={row.data5}
                  color={row.data5 === "Pendiente" ? "error" : "success"}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
