import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Table4Rows from "../../components/Table4Rows";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import NavBar from "../../components/NavBar";
import Back from "../../components/Back";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import FloatingButton from "../../components/FloatingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import BasicTextFields from "../../components/BasicTextFields";
import Grid from "@mui/material/Unstable_Grid2";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import PaidIcon from "@mui/icons-material/Paid";
import { getStudentByIdExternalUser } from "../../features/students/studentsSlice";
import moment from "moment";
import {
  insertAmount,
  deleteAmount,
} from "../../features/students/studentsSlice";
import BasicAlert from "../../components/BasicAlert";
import Loader from "../../components/Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

function Detail() {
  let location = useLocation();
  const path = location.pathname;
  const students = useSelector((state) => state.students.data);
  const auth = useSelector((state) => state.auth.uuid);
  const isLoadingStudents = useSelector((state) => state.students.isLoading);

  const uuid = path.split("/")[3];
  const [open, setOpen] = React.useState(false);
  const currentMonth = moment().format("M");
  const currentCycle =
    moment().format("YYYY") + "-" + (currentMonth > 6 ? "B" : "A");
  const [displayBasicAlert, setDisplayBasicAlert] = React.useState(false);

  const [payment, setPayment] = useState({
    amount: 0,
    idExternalUser: uuid,
    cycle: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStudentByIdExternalUser(uuid));
  }, [dispatch]);

  const handleClickOpen = () => {
    setDisplayBasicAlert(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPayment({
      amount: 0,
      cycle: "",
      idExternalUser: uuid,
    });
    setOpen(false);
  };

  const handleDeleteAmount = async (idAmount) => {
    await dispatch(deleteAmount({ idAmount, idUser: auth }))
      .then(async (response) => {
        if (response.error) {
          setDisplayBasicAlert(true);
        } else {
          await dispatch(getStudentByIdExternalUser(uuid));
        }
      })
      .catch((error) => {
        // Manejo de errores en caso de falla en la inserción del pago
        console.error("Error al eliminar el monto:", error);
        setDisplayBasicAlert(true);
      });
  };

  let dataPagos = [];
  let studentsAmounts = students.amounts ? students.amounts : [];

  const headersPagos = [
    studentsAmounts.length > 0
      ? {
          id: 1,
          title1: "Monto",
          title2: "Ciclo escolar",
          title3: "",
        }
      : {
          id: 1,
          title1: "",
          title2: "Aún no existe ningún monto",
          title3: "",
        },
  ];

  for (let index = 0; index < studentsAmounts.length; index++) {
    const amountsAux = studentsAmounts[index];

    dataPagos.push({
      data1: { type: "text", data: amountsAux.amount },
      data2: { type: "text", data: amountsAux.cycle },
      data3: {
        type: "button",
        data: (
          <IconButton
            onClick={() => handleDeleteAmount(amountsAux.id)}
            aria-label="add an alarm"
          >
            <DeleteIcon fontSize="medium" sx={{ color: "#ff0000 " }} />
          </IconButton>
        ),
      },
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "amount") {
      const numericValue = value.replace(/[^0-9.]/g, "");
      const sanitizedValue = numericValue.replace(/(\..*)\./g, "$1");

      setPayment({
        ...payment,
        [e.target.name]: sanitizedValue,
      });
      return;
    }

    if (name === "cycle") {
      setPayment({
        ...payment,
        [e.target.name]: value.toUpperCase(),
      });

      return;
    }

    setPayment({
      ...payment,
      [e.target.name]: e.target.value,
    });
  };

  const handleInsertPayment = async () => {
    const payload = {
      amount: payment.amount,
      idExternalUser: payment.idExternalUser,
      cycle: payment.cycle,
    };

    await dispatch(insertAmount(payload))
      .then(async (response) => {
        // Lógica adicional después de que se complete la inserción del pago
        if (response.error) {
          setDisplayBasicAlert(true);
        } else {
          await dispatch(getStudentByIdExternalUser(uuid));
        }
      })
      .catch((error) => {
        // Manejo de errores en caso de falla en la inserción del pago
        console.error("Error al insertar el pago:", error);
        setDisplayBasicAlert(true);
      });

    setPayment({
      amount: 0,
      cycle: "",
      idExternalUser: uuid,
    });

    setOpen(false);
  };

  return (
    <Box>
      {" "}
      <Loader loading={isLoadingStudents} />
      <Container>
        <NavBar />
        <FloatingButton onClick={handleClickOpen}></FloatingButton>
        <div>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Agregar monto</DialogTitle>
            <DialogContent>
              <Box sx={{ flexGrow: 1, m: 4 }}>
                <Grid spacing={2}>
                  <Grid xs={12} sx={{ my: 2 }}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <DescriptionIcon />
                        </InputAdornment>
                      }
                      label={"Ciclo Escolar (" + currentCycle + ")"}
                      onChange={handleChange}
                      name="cycle"
                      value={payment.cycle}
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12} sx={{ my: 2 }}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <PaidIcon />
                        </InputAdornment>
                      }
                      label="Cantidad de pago"
                      onChange={handleChange}
                      name="amount"
                      value={payment.amount}
                    ></BasicTextFields>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="error" onClick={handleClose}>
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleInsertPayment}
              >
                Agregar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Box sx={{ mt: -4 }}>
          <Back ruta={"/" + path.split("/")[1] + "/" + path.split("/")[2]} />
        </Box>
        <Box>
          <Box sx={{ mb: 3 }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 24, md: 32 },
                color: "#264496",
                fontFamily: "Nunito, sans-serif",
              }}
            >
              Historial de montos de
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 28, md: 36 },
                color: "#264496",
                fontFamily: "Nunito, sans-serif",
              }}
            >
              {students.name +
                (students.secondName ? " " + students.secondName + " " : " ") +
                students.lastName1 +
                " " +
                students.lastName2}
            </Typography>
          </Box>
          <Table4Rows admin={false} data={dataPagos} headers={headersPagos} />
        </Box>
        <BasicAlert
          display={displayBasicAlert}
          severity="error"
          label="Error al insertar monto"
        />
      </Container>
    </Box>
  );
}

export default Detail;
