import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Table4Rows from "../../components/Table4Rows";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import NavBar from "../../components/NavBar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Back from "../../components/Back";
import { getStudentAsync } from "../../features/students/studentsSlice";
import { getGeneralDataUserActiveList } from "../../features/generalDataUsers/generalDataUsersSlice";

import Loader from "../../components/Loader";
import SearchIcon from "@mui/icons-material/Search";
import BasicTextFields from "../../components/BasicTextFields";
import InputAdornment from "@mui/material/InputAdornment";
import { filterStudents } from "../../features/students/studentsSlice";
import { updateSemesterFilter } from "../../features/students/studentsSlice";
import {
  filterGeneralDataUsers,
  updateTypeFilter,
} from "../../features/generalDataUsers/generalDataUsersSlice";

import BasicSelect from "../../components/BasicSelect";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

function DashboardRights() {
  const isLoading = useSelector((state) => state.students.isLoading);
  const studentsFilter = useSelector((state) => state.students.filter);
  const generalDataUsersFilter = useSelector(
    (state) => state.generalDataUsers.filter
  );

  const [dataSearch, setDataSearch] = React.useState("");
  const [selectedType, setSelectedType] = useState("Planta");
  const dispatch = useDispatch();
  let location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getStudentAsync());
      await dispatch(filterStudents(""));
      await dispatch(updateSemesterFilter(0));
      await dispatch(getGeneralDataUserActiveList());
      await dispatch(filterGeneralDataUsers(""));
      await dispatch(updateTypeFilter("Planta"));
    };
    fetchData();
  }, [dispatch]);

  let data = [];
  let headers = [];

  if (selectedType === "Alumnos") {
    if (Array.isArray(studentsFilter)) {
      data = studentsFilter.map((student) => ({
        data1: {
          type: "text",
          data:
            student.name +
            (student.secondName !== null
              ? " " + student.secondName + " "
              : " ") +
            student.lastName1 +
            " " +
            student.lastName2,
        },
        data2: { type: "text", data: student.semester },
        data3: { type: "text", data: student.phone },
        id: student.idExternalUser,
      }));

      headers = studentsFilter.length
        ? [
            {
              id: 1,
              title1: "Nombre",
              title2: "Semestre",
              title3: "Teléfono",
            },
          ]
        : [
            {
              id: 1,
              title1: "",
              title2: "No hay datos disponibles",
              title3: "",
            },
          ];
    }
  } else if (selectedType === "Docentes" || selectedType === "Planta") {
    if (Array.isArray(generalDataUsersFilter)) {
      data = generalDataUsersFilter.map((generalDataUsers) => ({
        data1: {
          type: "text",
          data:
            generalDataUsers.name +
            (generalDataUsers.secondName !== null
              ? " " + generalDataUsers.secondName + " "
              : " ") +
            generalDataUsers.lastName1 +
            " " +
            generalDataUsers.lastName2,
        },
        data2: { type: "text", data: generalDataUsers.type },

        data3: { type: "text", data: generalDataUsers.phone },
        id: generalDataUsers.idExternalUser,
      }));

      headers = studentsFilter.length
        ? [
            {
              id: 1,
              title1: "Nombre",
              title2: "Tipo",
              title3: "Teléfono",
            },
          ]
        : [
            {
              id: 1,
              title1: "",
              title2: "No hay datos disponibles",
              title3: "",
            },
          ];
    }
  }

  const dataSelect = [
    {
      value: "Alumnos",
      label: "Alumnos",
    },
    {
      value: "Docentes",
      label: "Docentes",
    },
    {
      value: "Planta",
      label: "Planta",
    },
  ];

  const handleChange = async (newValue) => {
    if (newValue.target.name === "search") {
      setDataSearch(newValue.target.value);
      if (selectedType === "Alumnos") {
        await dispatch(filterStudents(newValue.target.value));
      } else if (selectedType === "Docentes" || selectedType === "Planta") {
        await dispatch(filterGeneralDataUsers(newValue.target.value));
      }
    } else if (newValue.target.name === "type") {
      setDataSearch("");

      if (newValue.target.value === "Alumnos") {
        await dispatch(filterStudents(""));
      } else if (
        newValue.target.value === "Docentes" ||
        newValue.target.value === "Planta"
      ) {
        await dispatch(updateTypeFilter(newValue.target.value));
      }

      setSelectedType(newValue.target.value);
    }
  };

  return (
    <Box>
      <Loader loading={isLoading} />
      <Container>
        <NavBar />
        <Back ruta={"/admin"} />
        <Box>
          <Box sx={{ mb: 3 }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 28, md: 36 },
                color: "#264496",
                fontFamily: "Nunito, sans-serif",
              }}
            >
              Administrador de derechos
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <BasicTextFields
                  startAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  label="Búsqueda"
                  onChange={handleChange}
                  name="search"
                  value={dataSearch}
                ></BasicTextFields>
              </Grid>
              <Grid item xs={4}>
                <BasicSelect
                  onChange={handleChange}
                  name="type"
                  data={dataSelect}
                  label="Tipo"
                  value={selectedType}
                ></BasicSelect>
              </Grid>
            </Grid>
          </Box>
          <Table4Rows admin={true} data={data} headers={headers} />
        </Box>
      </Container>
    </Box>
  );
}

export default DashboardRights;
