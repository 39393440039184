import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Table4Rows from "../../components/Table4RowsLinks";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import NavBar from "../../components/NavBar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Back from "../../components/Back";
import { getStudentAsync } from "../../features/students/studentsSlice";
import Loader from "../../components/Loader";
import SearchIcon from "@mui/icons-material/Search";
import BasicTextFields from "../../components/BasicTextFields";
import InputAdornment from "@mui/material/InputAdornment";
import { filterStudents } from "../../features/students/studentsSlice";
import { updateSemesterFilter } from "../../features/students/studentsSlice";
import BasicSelect from "../../components/BasicSelect";
import Grid from "@mui/material/Grid";

function SearchStudents() {
  const isLoading = useSelector((state) => state.students.isLoading);
  const studentsFilter = useSelector((state) => state.students.filter);
  const semesterFilter = useSelector((state) => state.students.semesterFilter);

  const [dataSearch, setDataSearch] = React.useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getStudentAsync());
      await dispatch(filterStudents(""));
      await dispatch(updateSemesterFilter(0));
    };

    fetchData();
  }, [dispatch]);

  let dataPagos = [];

  const headersPagos = [
    studentsFilter.length
      ? {
          id: 1,
          title1: "Alumno",
          title2: "Semestre",
          title3: "Referencia",
          title4: "Pagos",
          title5: "Status",
        }
      : {
          id: 1,
          title1: "",
          title2: "No hay datos disponibles",
          title3: "",
          title4: "",
          title5: "",
        },
  ];

  for (let index = 0; index < studentsFilter.length; index++) {
    dataPagos.push({
      id: studentsFilter[index].idExternalUser,
      data1:
        studentsFilter[index].name +
        (studentsFilter[index].secondName !== null
          ? " " + studentsFilter[index].secondName + " "
          : " ") +
        studentsFilter[index].lastName1 +
        " " +
        studentsFilter[index].lastName2,
      data2: studentsFilter[index].semester,
      data3: studentsFilter[index].reference,
      data4:
        "$" +
        studentsFilter[index].amountPaid +
        " / $" +
        studentsFilter[index].amount,
      data5:
        studentsFilter[index].statusPaid === 1 ? "Al corriente" : "Pendiente",
    });
  }

  const data = [
    {
      value: 0,
      label: "Todos",
    },
    {
      value: 1,
      label: "Primer semestre",
    },
    {
      value: 2,
      label: "Segundo semestre",
    },
    {
      value: 3,
      label: "Tercer semestre",
    },
    {
      value: 4,
      label: "Cuarto semestre",
    },
    {
      value: 5,
      label: "Quinto semestre",
    },
    {
      value: 6,
      label: "Sexto semestre",
    },
  ];

  const handleChange = async (newValue) => {
    if (newValue.target.name === "search") {
      setDataSearch(newValue.target.value);
      await dispatch(filterStudents(newValue.target.value));
    } else if (newValue.target.name === "semester") {
      await dispatch(updateSemesterFilter(newValue.target.value));
      setDataSearch("");
    }
  };

  return (
    <Box>
      <Loader loading={isLoading} />
      <Container>
        <NavBar />

        <Back ruta={"/admin"} />
        <Box>
          <Box sx={{ mb: 3 }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 28, md: 36 },
                color: "#264496",
                fontFamily: "Nunito, sans-serif",
              }}
            >
              Sistema de Pagos
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <BasicTextFields
                  startAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  label="Búsqueda"
                  onChange={handleChange}
                  name="search"
                  value={dataSearch}
                ></BasicTextFields>
              </Grid>
              <Grid item xs={4}>
                <BasicSelect
                  onChange={handleChange}
                  name="semester"
                  data={data}
                  label="Semestre"
                  value={semesterFilter}
                ></BasicSelect>
              </Grid>
            </Grid>
          </Box>

          <Table4Rows admin={true} data={dataPagos} headers={headersPagos} />
        </Box>
      </Container>
    </Box>
  );
}

export default SearchStudents;
