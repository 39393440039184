import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Chip from "@mui/material/Chip";

export default function CustomizedTables(data) {
  const history = useNavigate();
  const location = useLocation();
  const path=location.pathname;
  let rows = data.data;
  let headers = data.headers;
  let admin = data.admin;
  let routeLink = data.routeLink;

  const defaultStyleTableCell = {
    borderBottom: "none",
    marginTop: 3,
    padding: "17px 0 17px 0",
    fontSize: 16,
    fontFamily: "Nunito, sans-serif"
  };

  const titleCell = {
    borderBottom: "none",
    padding: `0px 2px`,
    lineHeight: "0.9rem",
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: 20,
    fontFamily: "Nunito, sans-serif"
  };

  const getTableCellFirstStyle = (colorTableCellFirst) => ({
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    borderLeftColor: colorTableCellFirst ? colorTableCellFirst: "#000000",
    borderLeftStyle: "solid",
    borderLeftWidth: 12,
    padding: 0,
  });

  return (
    <TableContainer>
      <Table
        stickyHeader={true}
        sx={{ width: "99.9%", borderSpacing: `0 20px` }}
        aria-label="customized table"
      >
        <TableHead>
          {headers.map((header, index) => (
            <TableRow key={"header-"+index}>
              {Object.keys(header).map((title,key) =>
                title !== "id" ? (
                  <TableCell key={"TableCell-header-"+key} sx={{ ...titleCell }} align="center">
                    {header[title]}
                  </TableCell>
                ) : null
              )}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {rows.map((row, indexRows) => (
            <TableRow
              onClick={() => row.id ?  history(path+(routeLink ? routeLink:"/")+row.id): console.log()}
              key={"TableRow-"+indexRows}
              sx={{ borderRadius: 3, boxShadow: 4 }}
            >
              {Object.keys(row).map((rowBody, index) =>
                rowBody !== "id" ? (
                  index === 0 ? (
                    <TableCell
                      key={"TableCell-"+index}
                      sx={{ ...defaultStyleTableCell, ...getTableCellFirstStyle(row[rowBody].colorTableCellFirst) }}
                      align="center"
                    >
                      {row[rowBody].data}
                    </TableCell>
                  ) : (
                    <TableCell
                      key={"TableCell-"+index}
                      sx={{ ...defaultStyleTableCell }}
                      align="center"
                    >
                      {row[rowBody].type === "url" ? (
                        <IconButton
                          onClick={() => window.open(row[rowBody].data)}
                          aria-label="add an alarm"
                        >
                          <PictureAsPdfIcon
                            fontSize="medium"
                            sx={{ color: "#1a75ce" }}
                          />
                        </IconButton>
                      ) : row[rowBody].type === "chip" ? (
                        <Chip
                        label={row[rowBody].data}
                        color={row[rowBody].color  ? row[rowBody].color : "success"}
                      />

                      ): (
                        row[rowBody].data
                      )}
                    </TableCell>
                  )
                ) : null
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
