import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export default function BasicTextFields({ label, startAdornment,onChange,name,value,disabled }) {
  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: "100%" },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        InputProps={{
          startAdornment: startAdornment,
        }}
        id="outlined-basic"
        label={label}
        variant="outlined"
        size="large"
        fullWidth 
        onChange={onChange}
        name={name}
        value={value}
        disabled={disabled}
        sx={{
          fontFamily: "Nunito, sans-serif",
          borderRadius: "100px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "100px",
          },
          "& .MuiInputLabel-root": {
            borderRadius: "100px",
          },
        }}
      />
    </Box>
  );
}
