import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import NavBar from "../../components/NavBar";
import BasicTextFields from "../../components/BasicTextFields";
import Grid from "@mui/material/Unstable_Grid2";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import BasicSelect from "../../components/BasicSelect";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import BasicDatePicker from "../../components/BasicDatePicker";
import Back from "../../components/Back";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import BasicButton from "../../components/BasicButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DialogConfirmation from "../../components/DialogConfirmation";
import { useLocation } from "react-router-dom";
import {
  upsert,
  getGeneralDataUserByIdExternalUser,
} from "../../features/generalDataUsers/generalDataUsersSlice";
import Loader from "../../components/Loader";
import FileInput from "../../components/FileInput";
import BasicAlert from "../../components/BasicAlert";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AppsIcon from "@mui/icons-material/Apps";
import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import PinIcon from "@mui/icons-material/Pin";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import {
  dataTutorRelationship,
  dataTypeOfScholarship,
  dataSex,
  dataTypeStaffMember,
  dataStatus,
  dataStatusAlumno,
  dataLevelStudy,
  dataAcademy,
  dataScholarshipStatus,
  dataPercentageScholarship,
  dataType,
} from "../../helpers/catalogs";

function AddStaff() {
  const history = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const segments = path.split("/");
  segments.pop();
  const beforeUrl = segments.join("/");

  const [open, setOpen] = React.useState(false);
  const [dialogResponse, setDialogResponse] = React.useState("");
  const staffData = useSelector((state) => state.generalDataUsers.data);
  const uuid = path.split("/")[3];
  const isLoading = useSelector((state) => state.generalDataUsers.isLoading);
  const [file, setFile] = React.useState(null);
  const [displayBasicAlert, setDisplayBasicAlert] = React.useState(false);
  const [displayBasicAlertSuccess, setDisplayBasicAlertSuccess] =
    React.useState(false);
  const [defaultStatus, setDefaultStatus] = useState(staffData.status);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!uuid.includes("agregar")) {
      const fetchData = async () => {
        await dispatch(getGeneralDataUserByIdExternalUser(uuid));
      };

      fetchData();
    }
  }, [dispatch, uuid]);

  const [staff, setStaff] = useState({
    id: "",
    idExternalUser: "",
    name: "",
    secondName: "",
    lastName1: "",
    lastName2: "",
    sex: "",
    birthDate: "",
    curp: "",
    email: "",
    phone: "",
    placeBirth: "",
    nationality: "",
    address: "",
    zip: "",
    type: "",
    pathPhoto: "",
    status: 1,
    dateAdmission: "",
    //datos de docente
    degree: "",
    levelStudy: "",
    specialism: "",
    academy: "",
    //datos personal planta
    typeStaffMember: "",
    //datos de alumno
    statusPaid: "",
    reference: "",
    startGeneration: "",
    endGeneration: "",
    tutorFullName: "",
    scholarshipStatus: "",
    scholarshipPercentage: "",
    typeOfScholarship: "",
    tutorRelationship: "",
    tutorPhone: "",
    emailTutor: "",
    bloodType: "",
    schoolOrigin: "",
    schoolAverage: "",
    registration: "",
    professionTutor:"",
    enterpriceTutor:"",
    enterpriceAddressTutor:"",
    tutorFullName2: "",
    tutorRelationship2: "",
    tutorPhone2: "",
    emailTutor2: "",
    professionTutor2:"",
    enterpriceTutor2:"",
    enterpriceAddressTutor2:"",
  });

  useEffect(() => {
    setDefaultStatus(staff.status);
  }, [staff.status]);

  useEffect(() => {
    if (staffData) {
      setStaff({
        id: staffData.id || "",
        idExternalUser: staffData.idExternalUser || "",
        name: staffData.name || "",
        secondName: staffData.secondName || "",
        lastName1: staffData.lastName1 || "",
        lastName2: staffData.lastName2 || "",
        sex: staffData.sex || "",
        birthDate: staffData.birthDate || "",
        curp: staffData.curp || "",
        email: staffData.email || "",
        phone: staffData.phone || "",
        placeBirth: staffData.placeBirth || "",
        nationality: staffData.nationality || "",
        address: staffData.address || "",
        zip: staffData.zip || "",
        type: staffData.type || "",
        pathPhoto: staffData.pathPhoto || "",
        status: staffData.status || "",
        dateAdmission: staffData.dateAdmission || "",
        //datos de docente
        degree: staffData.degree || "",
        levelStudy: staffData.levelStudy || "",
        specialism: staffData.specialism || "",
        academy: staffData.academy || "",
        typeStaffMember: staffData.typeStaffMember || "",
        //datos de alumno
        statusPaid: staffData.statusPaid || "",
        reference: staffData.reference || "",
        startGeneration: staffData.startGeneration || "",
        endGeneration: staffData.endGeneration || "",
        tutorFullName: staffData.tutorFullName || "",
        scholarshipStatus: staffData.scholarshipStatus || "",
        scholarshipPercentage: staffData.scholarshipPercentage || "",
        typeOfScholarship: staffData.typeOfScholarship || "",
        tutorRelationship: staffData.tutorRelationship || "",
        tutorPhone: staffData.tutorPhone || "",
        emailTutor: staffData.emailTutor || "",
        bloodType: staffData.bloodType || "",
        schoolOrigin: staffData.schoolOrigin || "",
        schoolAverage: staffData.schoolAverage || "",
        registration: staffData.registration || "",
        professionTutor: staffData.professionTutor || "",
        enterpriceTutor:staffData.enterpriceTutor || "",
        enterpriceAddressTutor:staffData.enterpriceAddressTutor || "",
        tutorFullName2: staffData.tutorFullName2 || "",
        tutorRelationship2: staffData.tutorRelationship2 || "",
        tutorPhone2: staffData.tutorPhone2 || "",
        emailTutor2: staffData.emailTutor2 || "",
        professionTutor2:staffData.professionTutor2 || "",
        enterpriceTutor2:staffData.enterpriceTutor2 || "",
        enterpriceAddressTutor2:staffData.enterpriceAddressTutor2 || "",
      });
      setDefaultStatus(staff.status);
    }
  }, [staffData]);

  const handleChange = (e) => {
    if (
      e.target.name === "phone" ||
      e.target.name === "zip" ||
      e.target.name === "reference" ||
      e.target.name === "startGeneration" ||
      e.target.name === "endGeneration" ||
      e.target.name === "tutorPhone"
    ) {
      const numericValue = e.target.value.replace(/[^0-9]/g, "");
      setStaff({
        ...staff,
        [e.target.name]: numericValue,
      });
    } else if (e.target.name === "curp") {
      setStaff({
        ...staff,
        [e.target.name]: e.target.value.toUpperCase(),
      });

      return;
    } else {
      setStaff({
        ...staff,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onChangeDatePicker = (data) => {
    setStaff({
      ...staff,
      [data.name]: data.date,
    });
  };

  React.useEffect(() => {
    if (dialogResponse === "option2") {
      history(beforeUrl);
    } else if (dialogResponse === "option1") {
      setDialogResponse("");

      setStaff({
        id: "",
        idExternalUser: "",
        name: "",
        secondName: "",
        lastName1: "",
        lastName2: "",
        sex: "",
        birthDate: "",
        curp: "",
        email: "",
        phone: "",
        placeBirth: "",
        nationality: "",
        address: "",
        zip: "",
        type: "",
        pathPhoto: "",
        status: 1,
        dateAdmission: 1,
        //datos de docente
        degree: "",
        levelStudy: "",
        specialism: "",
        academy: "",
        //datos de planta
        typeStaffMember: "",
        //datos de alumno
        statusPaid: "",
        reference: "",
        startGeneration: "",
        endGeneration: "",
        tutorFullName: "",
        scholarshipStatus: "",
        scholarshipPercentage: "",
        typeOfScholarship: "",
        tutorRelationship: "",
        tutorPhone: "",
        emailTutor: "",
        bloodType: "",
        schoolOrigin: "",
        schoolAverage: "",
        registration: "",
        professionTutor:"",
        enterpriceTutor:"",
        enterpriceAddressTutor:"",
        tutorFullName2: "",
        tutorRelationship2: "",
        tutorPhone2: "",
        emailTutor2: "",
        professionTutor2:"",
        enterpriceTutor2:"",
        enterpriceAddressTutor2:"",
      });
    }
  }, [staff, dialogResponse]);

  const handleInsertStaff = async () => {
    let formData = new FormData();
    formData.append("idExternalUser", staff.idExternalUser);
    formData.append("pathPhoto", file);
    formData.append("name", staff.name);
    formData.append("secondName", staff.secondName);
    formData.append("lastName1", staff.lastName1);
    formData.append("lastName2", staff.lastName2);
    formData.append("sex", staff.sex);
    formData.append("birthDate", staff.birthDate);
    formData.append("curp", staff.curp);
    formData.append("email", staff.email);
    formData.append("phone", staff.phone);
    formData.append("placeBirth", staff.placeBirth);
    formData.append("nationality", staff.nationality);
    formData.append("address", staff.address);
    formData.append("zip", staff.zip);
    formData.append("type", staff.type);
    formData.append("status", staff.status);
    formData.append("dateAdmission", staff.dateAdmission);
    //datos de docente
    formData.append("degree", staff.degree);
    formData.append("levelStudy", staff.levelStudy);
    formData.append("specialism", staff.specialism);
    formData.append("academy", staff.academy);
    //datos de planta
    formData.append("typeStaffMember", staff.typeStaffMember);
    //datos de alumno
    formData.append("statusPaid", staff.statusPaid);
    formData.append("reference", staff.reference);
    formData.append("startGeneration", staff.startGeneration);
    formData.append("endGeneration", staff.endGeneration);
    formData.append("tutorFullName", staff.tutorFullName);
    formData.append("scholarshipStatus", staff.scholarshipStatus);
    formData.append("scholarshipPercentage", staff.scholarshipPercentage);
    formData.append("typeOfScholarship", staff.typeOfScholarship);
    formData.append("tutorRelationship", staff.tutorRelationship);
    formData.append("tutorPhone", staff.tutorPhone);
    formData.append("emailTutor", staff.emailTutor);
    formData.append("bloodType", staff.bloodType);
    formData.append("schoolOrigin", staff.schoolOrigin);
    formData.append("schoolAverage", staff.schoolAverage);
    formData.append("registration", staff.registration);
    formData.append("professionTutor", staff.professionTutor);
    formData.append("enterpriceTutor", staff.enterpriceTutor);
    formData.append("enterpriceAddressTutor", staff.enterpriceAddressTutor);
    formData.append("tutorFullName2", staff.tutorFullName2);
    formData.append("tutorRelationship2", staff.tutorRelationship2);
    formData.append("tutorPhone2", staff.tutorPhone2);
    formData.append("emailTutor2", staff.emailTutor2);
    formData.append("professionTutor2", staff.professionTutor2);
    formData.append("enterpriceTutor2", staff.enterpriceTutor2);
    formData.append("enterpriceAddressTutor2", staff.enterpriceAddressTutor2);

    try {
      const response = await dispatch(upsert(formData));
      if (response.error) {
        setDisplayBasicAlert(true);
      } else {
        // Actualizar el estado después de una respuesta exitosa
        setDisplayBasicAlertSuccess(true);
        setFile(null);
        setStaff({});

        // Obtener los datos actualizados
        if (uuid !== "agregar") {
          await dispatch(getGeneralDataUserByIdExternalUser(uuid));
        }
      }
    } catch (error) {
      // Manejo de errores en caso de falla en la inserción
      console.error("Error al insertar el personal de planta:", error);
      setDisplayBasicAlert(true);
    }
  };

  const handleChangeFile = (newFile) => {
    setFile(newFile);
  };

  return (
    <Box>
      <Loader loading={isLoading} />
      <Container>
        <NavBar />
        <DialogConfirmation
          title={"Registro guardado correctamente"}
          contetText={
            "El registro se ha realizado con éxito, seleccione alguna opción para continuar"
          }
          opction1Text={"Nuevo registro"}
          option2Text={"Cerrar"}
          open={open}
          setOpen={setOpen}
          setDialogResponse={setDialogResponse}
        />
        <Box>
          <Back ruta={beforeUrl} />
          <Box sx={{ mb: 3 }}>
            <Typography
              sx={{ fontWeight: "bold", fontSize: { xs: 28, md: 36 } }}
            >
              {uuid.includes("agregar")
                ? "Registro de usuario "
                : "Actualización de usuario"}
            </Typography>
            <Box sx={{ flexGrow: 1, mt: 1 }}>
              <Box sx={{ mb: 3 }}>
                <Avatar
                  alt="Avatar"
                  src={staff.pathPhoto}
                  sx={{
                    width: 250,
                    height: 250,
                    margin: "0 auto",
                    mb: "20px",
                  }}
                />
                <FileInput
                  file={file}
                  placeholder="Foto"
                  handleChangeFile={handleChangeFile}
                  accept="image/*"
                ></FileInput>
              </Box>
              <Box sx={{ px: { xs: 0, md: 8 }, mt: 1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mb: 2 }}
                  align="left"
                >
                  Tipo de Usuario
                </Typography>
                <Grid xs={12} md={6}>
                  <BasicSelect
                    value={staff.type}
                    onChange={handleChange}
                    name="type"
                    data={dataType}
                    label="Tipo de usuario"
                    disabled={staffData.type}
                  ></BasicSelect>
                </Grid>
              </Box>
              {staff.type && (
                <Box>
                  <Box sx={{ px: { xs: 0, md: 8 }, mt: 1 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bold", mb: 2 }}
                      align="left"
                    >
                      Datos Generales
                    </Typography>
                    <Grid container spacing={2} sx={{ px: { xs: 0, md: 2 } }}>
                      <Grid xs={12} md={6}>
                        <BasicTextFields
                          startAdornment={
                            <InputAdornment position="start">
                              <AccountCircleRoundedIcon />
                            </InputAdornment>
                          }
                          label="Primer nombre"
                          onChange={handleChange}
                          name="name"
                          value={staff.name}
                        ></BasicTextFields>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <BasicTextFields
                          startAdornment={
                            <InputAdornment position="start">
                              <AccountCircleRoundedIcon />
                            </InputAdornment>
                          }
                          label="Segundo nombre"
                          onChange={handleChange}
                          name="secondName"
                          value={staff.secondName}
                        ></BasicTextFields>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <BasicTextFields
                          startAdornment={
                            <InputAdornment position="start">
                              <AccountCircleRoundedIcon />
                            </InputAdornment>
                          }
                          label="Apellido paterno"
                          onChange={handleChange}
                          name="lastName1"
                          value={staff.lastName1}
                        ></BasicTextFields>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <BasicTextFields
                          startAdornment={
                            <InputAdornment position="start">
                              <AccountCircleRoundedIcon />
                            </InputAdornment>
                          }
                          label="Apellido materno"
                          name="lastName2"
                          value={staff.lastName2}
                          onChange={handleChange}
                        ></BasicTextFields>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <BasicSelect
                          value={staff.sex}
                          onChange={handleChange}
                          name="sex"
                          data={dataSex}
                          label="Sexo"
                        ></BasicSelect>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <Box sx={{ mt: 1 }}>
                          <BasicDatePicker
                            name="birthDate"
                            onChangeDatePicker={onChangeDatePicker}
                            label={"Fecha de nacimiento"}
                            defaultValue={staff.birthDate}
                          ></BasicDatePicker>
                        </Box>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <BasicTextFields
                          startAdornment={
                            <InputAdornment position="start">
                              <AppsIcon />
                            </InputAdornment>
                          }
                          label="CURP"
                          onChange={handleChange}
                          name="curp"
                          value={staff.curp}
                        ></BasicTextFields>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <BasicTextFields
                          startAdornment={
                            <InputAdornment position="start">
                              <EmailRoundedIcon />
                            </InputAdornment>
                          }
                          label="Correo"
                          onChange={handleChange}
                          name="email"
                          value={staff.email}
                          disabled={staffData.email ? true : false}
                        ></BasicTextFields>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <BasicTextFields
                          startAdornment={
                            <InputAdornment position="start">
                              <LocalPhoneIcon />
                            </InputAdornment>
                          }
                          label="Teléfono"
                          onChange={handleChange}
                          name="phone"
                          value={staff.phone}
                        ></BasicTextFields>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <BasicTextFields
                          startAdornment={
                            <InputAdornment position="start">
                              <HomeIcon />
                            </InputAdornment>
                          }
                          label="Ciudad de origen"
                          onChange={handleChange}
                          name="placeBirth"
                          value={staff.placeBirth}
                        ></BasicTextFields>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <BasicTextFields
                          startAdornment={
                            <InputAdornment position="start">
                              <HomeIcon />
                            </InputAdornment>
                          }
                          label="País de origen"
                          onChange={handleChange}
                          name="nationality"
                          value={staff.nationality}
                        ></BasicTextFields>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <BasicTextFields
                          startAdornment={
                            <InputAdornment position="start">
                              <HomeIcon />
                            </InputAdornment>
                          }
                          label="Dirección"
                          onChange={handleChange}
                          name="address"
                          value={staff.address}
                        ></BasicTextFields>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <BasicTextFields
                          startAdornment={
                            <InputAdornment position="start">
                              <HomeIcon />
                            </InputAdornment>
                          }
                          label="Código postal"
                          onChange={handleChange}
                          name="zip"
                          value={staff.zip}
                        ></BasicTextFields>
                      </Grid>

                      {staff.type === "Planta" && (
                        <>
                          <Grid xs={12} md={6}>
                            <Box sx={{ mt: 1 }}>
                              <BasicDatePicker
                                name="dateAdmission"
                                onChangeDatePicker={onChangeDatePicker}
                                label={"Fecha de ingreso"}
                                defaultValue={staff.dateAdmission}
                              ></BasicDatePicker>
                            </Box>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicSelect
                              value={staff.typeStaffMember}
                              onChange={handleChange}
                              name="typeStaffMember"
                              data={dataTypeStaffMember}
                              label="Tipo de personal"
                            ></BasicSelect>
                          </Grid>
                        </>
                      )}

                      {staff.type === "Alumno" ? (
                        <>
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <BloodtypeIcon />
                                </InputAdornment>
                              }
                              label="Tipo de sangre"
                              onChange={handleChange}
                              name="bloodType"
                              value={staff.bloodType}
                            ></BasicTextFields>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <SchoolIcon />
                                </InputAdornment>
                              }
                              label="Secundaria de procedencia"
                              onChange={handleChange}
                              name="schoolOrigin"
                              value={staff.schoolOrigin}
                            ></BasicTextFields>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <SchoolIcon />
                                </InputAdornment>
                              }
                              label="Promedio de secundaria"
                              onChange={handleChange}
                              name="schoolAverage"
                              value={staff.schoolAverage}
                            ></BasicTextFields>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <AppRegistrationIcon />
                                </InputAdornment>
                              }
                              label="Matrícula"
                              onChange={handleChange}
                              name="registration"
                              value={staff.registration}
                            ></BasicTextFields>
                          </Grid>
                        </>
                      ) : null}
                      {staff.type === "Docente" && (
                        <Grid xs={12} md={6}>
                          <Box sx={{ mt: 1 }}>
                            <BasicDatePicker
                              name="dateAdmission"
                              onChangeDatePicker={onChangeDatePicker}
                              label={"Fecha de ingreso"}
                              defaultValue={staff.dateAdmission}
                            ></BasicDatePicker>
                          </Box>
                        </Grid>
                      )}
                      {staffData.status || staff.type === "Alumno" ? (
                        <Grid xs={12} md={6}>
                          <BasicSelect
                            value={staff.status}
                            onChange={handleChange}
                            name="status"
                            data={
                              staff.type !== "Alumno"
                                ? dataStatus
                                : dataStatusAlumno
                            }
                            label="Estado"
                          ></BasicSelect>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>
                  {staff.type === "Docente" && (
                    <Box sx={{ px: { xs: 0, md: 8 }, mt: 1 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold", mb: 2 }}
                        align="left"
                      >
                        Escolaridad
                      </Typography>
                      <Grid container spacing={2} sx={{ px: { xs: 0, md: 2 } }}>
                        <Grid xs={12} md={6}>
                          <BasicTextFields
                            startAdornment={
                              <InputAdornment position="start">
                                <SchoolIcon />
                              </InputAdornment>
                            }
                            label="Titulo"
                            onChange={handleChange}
                            name="degree"
                            value={staff.degree}
                          ></BasicTextFields>
                        </Grid>
                        <Grid xs={12} md={6}>
                          <BasicSelect
                            value={staff.levelStudy}
                            onChange={handleChange}
                            name="levelStudy"
                            data={dataLevelStudy}
                            label="Nivel de estudios"
                          ></BasicSelect>
                        </Grid>
                        <Grid xs={12} md={6}>
                          <BasicTextFields
                            startAdornment={
                              <InputAdornment position="start">
                                <SchoolIcon />
                              </InputAdornment>
                            }
                            label="Especialidad"
                            onChange={handleChange}
                            name="specialism"
                            value={staff.specialism}
                          ></BasicTextFields>
                        </Grid>
                        <Grid xs={12} md={6}>
                          <BasicSelect
                            value={staff.academy}
                            onChange={handleChange}
                            name="academy"
                            data={dataAcademy}
                            label="Academia"
                          ></BasicSelect>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {staff.type === "Alumno" && (
                    <Box>
                      <Box sx={{ px: { xs: 0, md: 8 } }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "bold", mb: 2 }}
                          align="left"
                        >
                          Generación
                        </Typography>
                        <Grid
                          container
                          spacing={2}
                          sx={{ px: { xs: 0, md: 2 } }}
                        >
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <SchoolIcon />
                                </InputAdornment>
                              }
                              label="Inicio de generación"
                              onChange={handleChange}
                              name="startGeneration"
                              value={staff.startGeneration}
                            ></BasicTextFields>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <SchoolIcon />
                                </InputAdornment>
                              }
                              label="Fin de generación"
                              onChange={handleChange}
                              name="endGeneration"
                              value={staff.endGeneration}
                            ></BasicTextFields>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box sx={{ px: { xs: 0, md: 8 }, mt: 1 }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "bold", mb: 2 }}
                          align="left"
                        >
                          Datos Administrativos
                        </Typography>
                        <Grid
                          container
                          spacing={2}
                          sx={{ px: { xs: 0, md: 2 } }}
                        >
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <PinIcon />
                                </InputAdornment>
                              }
                              label="Referencia"
                              onChange={handleChange}
                              name="reference"
                              value={staff.reference}
                            ></BasicTextFields>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box sx={{ px: { xs: 0, md: 8 }, mt: 1 }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "bold", mb: 2 }}
                          align="left"
                        >
                          Becas
                        </Typography>
                        <Grid
                          container
                          spacing={2}
                          sx={{ px: { xs: 0, md: 2 } }}
                        >
                          <Grid xs={12} md={6}>
                            <BasicSelect
                              value={staff.scholarshipStatus}
                              onChange={handleChange}
                              name="scholarshipStatus"
                              data={dataScholarshipStatus}
                              label="Estado de beca"
                            ></BasicSelect>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicSelect
                              value={staff.scholarshipPercentage}
                              onChange={handleChange}
                              name="scholarshipPercentage"
                              data={dataPercentageScholarship}
                              label="Porcentaje de beca"
                              //defaultValue={defaultStatus}
                            ></BasicSelect>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicSelect
                              value={staff.typeOfScholarship}
                              onChange={handleChange}
                              name="typeOfScholarship"
                              data={dataTypeOfScholarship}
                              label="Tipo de beca"
                              //defaultValue={defaultStatus}
                            ></BasicSelect>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box sx={{ px: { xs: 0, md: 8 }, mt: 1 }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "bold", mb: 2 }}
                          align="left"
                        >
                          Datos Tutor/a 1
                        </Typography>
                        <Grid
                          container
                          spacing={2}
                          sx={{ px: { xs: 0, md: 2 } }}
                        >
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <AccountCircleRoundedIcon />
                                </InputAdornment>
                              }
                              label="Nombre completo"
                              onChange={handleChange}
                              name="tutorFullName"
                              value={staff.tutorFullName}
                            ></BasicTextFields>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <AccountCircleRoundedIcon />
                                </InputAdornment>
                              }
                              label="Profesión"
                              onChange={handleChange}
                              name="professionTutor"
                              value={staff.professionTutor}
                            ></BasicTextFields>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <AccountCircleRoundedIcon />
                                </InputAdornment>
                              }
                              label="Nombre de la empresa"
                              onChange={handleChange}
                              name="enterpriceTutor"
                              value={staff.enterpriceTutor}
                            ></BasicTextFields>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <AccountCircleRoundedIcon />
                                </InputAdornment>
                              }
                              label="Dirección y C.P. de la empresa"
                              onChange={handleChange}
                              name="enterpriceAddressTutor"
                              value={staff.enterpriceAddressTutor}
                            ></BasicTextFields>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <EmailRoundedIcon />
                                </InputAdornment>
                              }
                              label="Correo"
                              onChange={handleChange}
                              name="emailTutor"
                              value={staff.emailTutor}
                            ></BasicTextFields>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicSelect
                              value={staff.tutorRelationship}
                              onChange={handleChange}
                              name="tutorRelationship"
                              data={dataTutorRelationship}
                              label="Parentesco"
                              //defaultValue={defaultStatus}
                            ></BasicSelect>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <LocalPhoneIcon />
                                </InputAdornment>
                              }
                              label="Teléfono"
                              onChange={handleChange}
                              name="tutorPhone"
                              value={staff.tutorPhone}
                            ></BasicTextFields>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box sx={{ px: { xs: 0, md: 8 }, mt: 1 }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "bold", mb: 2 }}
                          align="left"
                        >
                          Datos Tutor/a 2
                        </Typography>
                        <Grid
                          container
                          spacing={2}
                          sx={{ px: { xs: 0, md: 2 } }}
                        >
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <AccountCircleRoundedIcon />
                                </InputAdornment>
                              }
                              label="Nombre completo"
                              onChange={handleChange}
                              name="tutorFullName2"
                              value={staff.tutorFullName2}
                            ></BasicTextFields>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <AccountCircleRoundedIcon />
                                </InputAdornment>
                              }
                              label="Profesión"
                              onChange={handleChange}
                              name="professionTutor2"
                              value={staff.professionTutor2}
                            ></BasicTextFields>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <AccountCircleRoundedIcon />
                                </InputAdornment>
                              }
                              label="Nombre de la empresa"
                              onChange={handleChange}
                              name="enterpriceTutor2"
                              value={staff.enterpriceTutor2}
                            ></BasicTextFields>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <AccountCircleRoundedIcon />
                                </InputAdornment>
                              }
                              label="Dirección y C.P. de la empresa"
                              onChange={handleChange}
                              name="enterpriceAddressTutor2"
                              value={staff.enterpriceAddressTutor2}
                            ></BasicTextFields>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <EmailRoundedIcon />
                                </InputAdornment>
                              }
                              label="Correo"
                              onChange={handleChange}
                              name="emailTutor2"
                              value={staff.emailTutor2}
                            ></BasicTextFields>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicSelect
                              value={staff.tutorRelationship2}
                              onChange={handleChange}
                              name="tutorRelationship2"
                              data={dataTutorRelationship}
                              label="Parentesco"
                              //defaultValue={defaultStatus}
                            ></BasicSelect>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <BasicTextFields
                              startAdornment={
                                <InputAdornment position="start">
                                  <LocalPhoneIcon />
                                </InputAdornment>
                              }
                              label="Teléfono"
                              onChange={handleChange}
                              name="tutorPhone2"
                              value={staff.tutorPhone2}
                            ></BasicTextFields>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
      <Box sx={{ my: 2 }}>
        <BasicAlert
          display={displayBasicAlert}
          severity="error"
          label="Error al insertar Usuario"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setDisplayBasicAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        />
        <BasicAlert
          display={displayBasicAlertSuccess}
          severity="success"
          label="Datos actualizados"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setDisplayBasicAlertSuccess(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        />
      </Box>

      {staff.type && (
        <Box sx={{ mb: 4 }}>
          <BasicButton
            onClick={handleInsertStaff}
            size="large"
            label="Guardar"
          />
        </Box>
      )}
    </Box>
  );
}

export default AddStaff;
