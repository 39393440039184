import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import NavBar from "../../components/NavBar";
import BasicTextFields from "../../components/BasicTextFields";
import Grid from "@mui/material/Unstable_Grid2";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import BasicSelect from "../../components/BasicSelect";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import PaidIcon from "@mui/icons-material/Paid";
import BasicDatePicker from "../../components/BasicDatePicker";
import Back from "../../components/Back";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import BasicButton from "../../components/BasicButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DialogConfirmation from "../../components/DialogConfirmation";
import { useLocation } from "react-router-dom";
import {
  getStudentByIdExternalUser,
  upsertStudent,
} from "../../features/students/studentsSlice";
import Loader from "../../components/Loader";
import FileInput from "../../components/FileInput";
import BasicAlert from "../../components/BasicAlert";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SchoolIcon from "@mui/icons-material/School";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PinIcon from '@mui/icons-material/Pin';
import HomeIcon from "@mui/icons-material/Home";
import AppsIcon from "@mui/icons-material/Apps";

function AddUsers() {
  const history = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const segments = path.split("/");
  segments.pop();
  segments.pop();
  const beforeUrl = segments.join("/");
  const [open, setOpen] = React.useState(false);
  const [dialogResponse, setDialogResponse] = React.useState("");
  const studentsData = useSelector((state) => state.students.data);
  const uuid = path.split("/")[4];
  const isLoading = useSelector((state) => state.students.isLoading);
  const [file, setFile] = React.useState(null);
  const [displayBasicAlert, setDisplayBasicAlert] = React.useState(false);
  const [displayBasicAlertSuccess, setDisplayBasicAlertSuccess] =
    React.useState(false);
  const [defaultStatus, setDefaultStatus] = useState(studentsData.status);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!uuid.includes("agregar")) {
      const fetchData = async () => {
        await dispatch(getStudentByIdExternalUser(uuid));
      };

      fetchData();
    }
  }, [dispatch, uuid]);

  const [student, setStudent] = useState({
    id: "",
    name: "",
    secondName: "",
    lastName1: "",
    lastName2: "",
    statusPaid: "",
    amount: "",
    idExternalUser: "",
    idRegistro: "",
    pathPhoto: "",
    status: 0,
    emailStudent: "",
    startGeneration: "",
    endGeneration: "",
    tutorFullName:"",
    scholarshipStatus:"",
    scholarshipPercentage:"",
    typeOfScholarship:"",
    tutorRelationship:"",
    tutorPhone:"",
    emailTutor: "",
    phone:"",
    sex: "",
    birthDate: "",
    curp: "",
    placeBirth: "",
    nationality: "",
    address: "",
    zip: "",
  });

  useEffect(() => {
    setDefaultStatus(student.status);
  }, [student.status]);

  useEffect(() => {
    if (studentsData) {
      setStudent({
        id: studentsData.id || "",
        name: studentsData.name || "",
        secondName: studentsData.secondName || "",
        lastName1: studentsData.lastName1 || "",
        lastName2: studentsData.lastName2 || "",
        amount: studentsData.amount || "",
        idExternalUser: studentsData.idExternalUser || "",
        idRegistro: studentsData.idRegistro || "",
        pathPhoto: studentsData.pathPhoto || "",
        status: studentsData.status || "",
        emailStudent: studentsData.emailStudent || "",
        startGeneration: studentsData.startGeneration || "",
        endGeneration: studentsData.endGeneration || "",
        tutorFullName: studentsData.tutorFullName || "",
        scholarshipStatus: studentsData.scholarshipStatus || "",
        scholarshipPercentage:studentsData.scholarshipPercentage || "",
        typeOfScholarship:studentsData.typeOfScholarship || "",
        tutorRelationship:studentsData.tutorRelationship || "",
        tutorPhone:studentsData.tutorPhone || "",
        emailTutor: studentsData.emailTutor || "",
        phone:studentsData.phone || "",
        sex: studentsData.sex || "",
        birthDate: studentsData.birthDate || "",
        curp: studentsData.curp || "",
        placeBirth: studentsData.placeBirth || "",
        nationality: studentsData.nationality || "",
        address: studentsData.address || "",
        zip: studentsData.zip || "",
      });
      setDefaultStatus(student.status);
    }
  }, [studentsData]);

  const handleChange = (e) => {
    if (
      e.target.name === "amount" ||
      e.target.name === "startGeneration" ||
      e.target.name === "endGeneration" ||
      e.target.name === "phone" || 
      e.target.name === "tutorPhone"
    ) {
      const numericValue = e.target.value.replace(/[^0-9]/g, "");
      setStudent({
        ...student,
        [e.target.name]: numericValue,
      });
    } else {
      setStudent({
        ...student,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onChangeDatePicker = (data) => {
    setStudent({
      ...student,
      [data.name]: data.fecha,
    });
  };

  const handleSubmit = () => {
    //dispatch(addStudent({...student,id: uuid()}))
    //setOpen(true)
    //history("/admin/pagos/dashboard");
  };

  React.useEffect(() => {
    if (dialogResponse === "option2") {
      history(beforeUrl);
    } else if (dialogResponse === "option1") {
      setDialogResponse("");

      setStudent({
        id: "",
        name: "",
        secondName: "",
        lastName1: "",
        lastName2: "",
        emailStudent: "",
        emailTutor: "",
        status: "",
        amount: "",
        startGeneration: "",
        endGeneration: "",
        scholarshipStatus:"",
        scholarshipPercentage: "",
        typeOfScholarship: "",
        tutorRelationship:"",
        tutorPhone: "",
        phone:"",
        sex: "",
        birthDate: "",
        curp: "",
        placeBirth: "",
        nationality: "",
        address: "",
        zip: "",
      });
    }
  }, [student, dialogResponse]);

  const data = [
    {
      value: 1,
      label: "En curso",
    },
    {
      value: 2,
      label: "Graduado",
    },
    {
      value: 3,
      label: "Intercambio",
    },
    {
      value: 4,
      label: "Baja temporal",
    },
    {
      value: 5,
      label: "Baja definitiva",
    }
  ];

  const dataScholarshipStatus = [
    {
      value: "Ninguna",
      label: "Ninguna",
    },
    {
      value: "Otorgada",
      label: "Otorgada",
    },
    {
      value: "Refrendo",
      label: "Refrendo",
    },
    {
      value: "Condicionada",
      label: "Condicionada",
    },
    {
      value: "Reducción",
      label: "Reducción",
    },
    {
      value: "Eliminación",
      label: "Eliminación",
    },
  ];
  

  const dataPercentageScholarship = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 5,
      label: "5%",
    },
    {
      value: 10,
      label: "10%",
    },
    {
      value: 15,
      label: "15%",
    },
    {
      value: 20,
      label: "20%",
    },
    {
      value: 25,
      label: "25%",
    },
    {
      value: 30,
      label: "30%",
    },
    {
      value: 35,
      label: "35%",
    },
    {
      value: 40,
      label: "40%",
    },
    {
      value: 45,
      label: "45%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 55,
      label: "55%",
    },
    {
      value: 60,
      label: "60%",
    },
    {
      value: 65,
      label: "65%",
    },
    {
      value: 70,
      label: "70%",
    },
    {
      value: 75,
      label: "75%",
    },
    {
      value: 80,
      label: "80%",
    },
    {
      value: 85,
      label: "85%",
    },
    {
      value: 90,
      label: "90%",
    },
    {
      value: 95,
      label: "95%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];

  const dataTypeOfScholarship = [
    {
      value: "ninguna",
      label: "Ninguna",
    },
    {
      value: "tradicional",
      label: "Beca Tradicional",
    },
    {
      value: "excelencia",
      label: "Beca de excelencia",
    },
    {
      value: "merito",
      label: "Beca al mérito académico",
    },
    {
      value: "maestro",
      label: "Beca por maestro",
    },
    {
      value: "convenio",
      label: "Beca por convenio",
    },
    {
      value: "militar",
      label: "Beca militar",
    },
    {
      value: "lealtad",
      label: "Beca de lealtad",
    },
    {
      value: "intercambio",
      label: "Beca por intercambio",
    },
    {
      value: "acuerdo",
      label: "Beca por acuerdo",
    },
  ];

  const dataTutorRelationship = [
    {
      value: "Padre",
      label: "Padre",
    },
    {
      value: "Madre",
      label: "Madre",
    },
    {
      value: "Tío/a",
      label: "Tío/a",
    },
    {
      value: "Abuelo/a",
      label: "Abuelo/a",
    },
    {
      value: "Tutor",
      label: "Tutor",
    },
    {
      value: "Otro",
      label: "Otro",
    },
  ];

  const dataSex = [
    {
      value: "Hombre",
      label: "Hombre",
    },
    {
      value: "Mujer",
      label: "Mujer",
    },
    {
      value: "Otro",
      label: "Otro",
    },
  ];

  const handleInsertStudent = async () => {
    let formData = new FormData();
    formData.append("name", student.name);
    formData.append("secondName", student.secondName);
    formData.append("lastName1", student.lastName1);
    formData.append("lastName2", student.lastName2);
    formData.append("email", student.emailStudent);
    formData.append("status", student.status);
    formData.append("amount", student.amount);
    formData.append("idExternalUser", student.idExternalUser);
    formData.append(
      "idRegistro",
      student.idRegistro ? student.idRegistro : "0"
    );
    formData.append("pathPhoto", file);
    formData.append("startGeneration", student.startGeneration);
    formData.append("endGeneration", student.endGeneration);
    //datos del tutor
    formData.append("tutorFullName", student.tutorFullName);
    formData.append("emailTutor", student.emailTutor);
    formData.append("scholarshipStatus", student.scholarshipStatus);
    formData.append("scholarshipPercentage", student.scholarshipPercentage);
    formData.append("typeOfScholarship", student.typeOfScholarship);
    formData.append("tutorRelationship", student.tutorRelationship);
    formData.append("tutorPhone", student.tutorPhone);
    formData.append("sex", student.sex);
    formData.append("birthDate", student.birthDate);
    formData.append("curp", student.curp);
    formData.append("placeBirth", student.placeBirth);
    formData.append("nationality", student.nationality);
    formData.append("nationality", student.nationality);
    formData.append("address", student.address);
    formData.append("zip", student.zip);

    try {
      const response = await dispatch(upsertStudent(formData));
      if (response.error) {
        setDisplayBasicAlert(true);
      } else {
        // Actualizar el estado después de una respuesta exitosa
        setDisplayBasicAlertSuccess(true);
        setFile(null);

        // Obtener el estudiante actualizado
        if (uuid !== "agregar") {
          await dispatch(getStudentByIdExternalUser(uuid));
        }
      }
    } catch (error) {
      // Manejo de errores en caso de falla en la inserción del estudiante
      console.error("Error al insertar el estudiante:", error);
      setDisplayBasicAlert(true);
    }
  };

  const handleChangeFile = (newFile) => {
    setFile(newFile);
    // setStudent({
    // ...student,
    //["pathPhoto"]: file,
    //});
  };
  return (
    <Box>
      <Loader loading={isLoading} />
      <Container>
        <NavBar />
        <DialogConfirmation
          title={"Registro guardado correctamente"}
          contetText={
            "El registro se ha realizado con éxito, seleccione alguna opción para continuar"
          }
          opction1Text={"Nuevo registro"}
          option2Text={"Cerrar"}
          open={open}
          setOpen={setOpen}
          setDialogResponse={setDialogResponse}
        />
        <Box>
          <Back ruta={beforeUrl} />
          <Box sx={{ mb: 3 }}>
            <Typography
              sx={{ fontWeight: "bold", fontSize: { xs: 28, md: 36 } }}
            >
              {uuid.includes("agregar")?"Registro de Alumno":"Actualización de Alumno"}
            </Typography>
            <Box sx={{ flexGrow: 1, mt: 1 }}>
              <Box sx={{ mb: 3 }}>
                <Avatar
                  alt="Avatar"
                  src={student.pathPhoto}
                  sx={{
                    width: 250,
                    height: 250,
                    margin: "0 auto",
                    mb: "20px",
                  }}
                />
                <FileInput
                  file={file}
                  placeholder="Foto"
                  handleChangeFile={handleChangeFile}
                  accept="image/*"
                ></FileInput>
              </Box>
              <Box sx={{  px: { xs: 0, md: 8 } }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mb: 2 }}
                  align="left"
                >
                 Generación
                </Typography>
                <Grid container spacing={2} sx={{  px: { xs: 0, md: 2 } }} >
                  <Grid xs={12} md={6}>
                    <BasicSelect
                      value={parseInt(student.status)}
                      onChange={handleChange}
                      name="status"
                      data={data}
                      label="Estado"
                      //defaultValue={defaultStatus}
                    ></BasicSelect>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <SchoolIcon />
                        </InputAdornment>
                      }
                      label="Inicio de generación"
                      onChange={handleChange}
                      name="startGeneration"
                      value={student.startGeneration}
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <SchoolIcon />
                        </InputAdornment>
                      }
                      label="Fin de generación"
                      onChange={handleChange}
                      name="endGeneration"
                      value={student.endGeneration}
                    ></BasicTextFields>
                  </Grid>
                </Grid>
              </Box>
                            <Box sx={{  px: { xs: 0, md: 8 }, mt:1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mb: 2 }}
                  align="left"
                >
                  Datos Generales
                </Typography>
                <Grid container spacing={2} sx={{  px: { xs: 0, md: 2 } }}>
                  <Grid xs={12} md={6}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountCircleRoundedIcon />
                        </InputAdornment>
                      }
                      label="Primer nombre"
                      onChange={handleChange}
                      name="name"
                      value={student.name}
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountCircleRoundedIcon />
                        </InputAdornment>
                      }
                      label="Segundo nombre"
                      onChange={handleChange}
                      name="secondName"
                      value={student.secondName}
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountCircleRoundedIcon />
                        </InputAdornment>
                      }
                      label="Apellido paterno"
                      onChange={handleChange}
                      name="lastName1"
                      value={student.lastName1}
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountCircleRoundedIcon />
                        </InputAdornment>
                      }
                      label="Apellido materno"
                      name="lastName2"
                      value={student.lastName2}
                      onChange={handleChange}
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicSelect
                      value={student.sex}
                      onChange={handleChange}
                      name="sex"
                      data={dataSex}
                      label="Sexo"
                    ></BasicSelect>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Box sx={{ mt: 1 }}>
                      <BasicDatePicker
                        name="birthDate"
                        onChangeDatePicker={onChangeDatePicker}
                        label={"Fecha de nacimiento"}
                        defaultValue={student.birthDate}
                      ></BasicDatePicker>
                    </Box>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <AppsIcon />
                        </InputAdornment>
                      }
                      label="CURP"
                      onChange={handleChange}
                      name="curp"
                      value={student.curp}
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <EmailRoundedIcon />
                        </InputAdornment>
                      }
                      label="Correo"
                      onChange={handleChange}
                      name="emailStudent"
                      value={student.emailStudent}
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <LocalPhoneIcon />
                        </InputAdornment>
                      }
                      label="Teléfono"
                      onChange={handleChange}
                      name="phone"
                      value={student.phone}
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <HomeIcon />
                        </InputAdornment>
                      }
                      label="Ciudad de origen"
                      onChange={handleChange}
                      name="placeBirth"
                      value={student.placeBirth}
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <HomeIcon />
                        </InputAdornment>
                      }
                      label="País de origen"
                      onChange={handleChange}
                      name="nationality"
                      value={student.nationality}
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <HomeIcon />
                        </InputAdornment>
                      }
                      label="Dirección"
                      onChange={handleChange}
                      name="address"
                      value={student.address}
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <HomeIcon />
                        </InputAdornment>
                      }
                      label="Código postal"
                      onChange={handleChange}
                      name="zip"
                      value={student.zip}
                    ></BasicTextFields>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{  px: { xs: 0, md: 8 }, mt:1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mb: 2 }}
                  align="left"
                >
                  Datos Administrativos
                </Typography>
                <Grid container spacing={2} sx={{  px: { xs: 0, md: 2 } }}>
                  <Grid xs={12} md={6}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <PaidIcon />
                        </InputAdornment>
                      }
                      label="Monto semestre"
                      onChange={handleChange}
                      name="amount"
                      value={student.amount}
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <PinIcon />
                        </InputAdornment>
                      }
                      label="Referencia"
                      onChange={handleChange}
                      name="idRegistro"
                      value={student.idRegistro}
                    ></BasicTextFields>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{  px: { xs: 0, md: 8 }, mt:1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mb: 2 }}
                  align="left"
                >
                  Becas
                </Typography>
                <Grid container spacing={2} sx={{  px: { xs: 0, md: 2 } }}>
                <Grid xs={12} md={6}>
                    <BasicSelect
                      value={student.scholarshipStatus}
                      onChange={handleChange}
                      name="scholarshipStatus"
                      data={dataScholarshipStatus}
                      label="Estado de beca"
                    ></BasicSelect>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicSelect
                      value={student.scholarshipPercentage}
                      onChange={handleChange}
                      name="scholarshipPercentage"
                      data={dataPercentageScholarship}
                      label="Porcentaje de beca"
                      //defaultValue={defaultStatus}
                    ></BasicSelect>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicSelect
                      value={student.typeOfScholarship}
                      onChange={handleChange}
                      name="typeOfScholarship"
                      data={dataTypeOfScholarship}
                      label="Tipo de beca"
                      //defaultValue={defaultStatus}
                    ></BasicSelect>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{  px: { xs: 0, md: 8 }, mt:1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mb: 2 }}
                  align="left"
                >
                  Datos del Tutor
                </Typography>
                <Grid container spacing={2} sx={{  px: { xs: 0, md: 2 } }}>
                  <Grid xs={12} md={6}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountCircleRoundedIcon />
                        </InputAdornment>
                      }
                      label="Nombre completo"
                      onChange={handleChange}
                      name="tutorFullName"
                      value={student.tutorFullName}
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <EmailRoundedIcon />
                        </InputAdornment>
                      }
                      label="Correo tutor"
                      onChange={handleChange}
                      name="emailTutor"
                      value={student.emailTutor}
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicSelect
                      value={student.tutorRelationship}
                      onChange={handleChange}
                      name="tutorRelationship"
                      data={dataTutorRelationship}
                      label="Parentesco"
                      //defaultValue={defaultStatus}
                    ></BasicSelect>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <LocalPhoneIcon />
                        </InputAdornment>
                      }
                      label="Teléfono tutor"
                      onChange={handleChange}
                      name="tutorPhone"
                      value={student.tutorPhone}
                    ></BasicTextFields>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
      <Box sx={{ my: 2 }}>
        <BasicAlert
          display={displayBasicAlert}
          severity="error"
          label="Error al insertar Usuario"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setDisplayBasicAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        />
        <BasicAlert
          display={displayBasicAlertSuccess}
          severity="success"
          label="Datos actualizados"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setDisplayBasicAlertSuccess(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        />
      </Box>
      <BasicButton onClick={handleInsertStudent} size="large" label="Guardar" />
    </Box>
  );
}

export default AddUsers;
