import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import BasicAlert from "../../components/BasicAlert";
import { loginAsync } from "../../features/auth/authSlice";
import Loader from "../../components/Loader";
import logoIMC from "../../assets/imc-logo-right-white.png";

export default function SignIn() {
  const location = useLocation();
  const history = useNavigate();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();
    if (!email || !password) return;
    const payload = { user: email, password };
    await dispatch(loginAsync(payload));
  };

  const useRedirectAfterLogin = () => {
    useEffect(() => {
      if (auth.login) {
        const type = JSON.parse(auth.type);
        if (type.dashboard) {
          history("/alumnos");
        } else if (type.adminDashboard) {
          history("/admin");
        }
      }
    }, [auth, history]);
  };

  useRedirectAfterLogin();

  const alertMessage = auth.error
    ? {
        display: true,
        severity: "error",
        label: "Contraseña y/o usuario incorrecto",
      }
    : location.pathname === "/401"
    ? {
        display: true,
        severity: "warning",
        label:
          "Tu sesión ha expirado. Por favor, vuelve a iniciar sesión para continuar",
      }
    : { display: false };

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      sx={{
        width: "100%",
        height: "100vh",
        bgcolor: "#FFFFFF",
      }}
    >
      <Loader loading={auth.isLoading} />
      <Paper
        sx={{
          width: { xs: "100%", md: "30%" },
          height: { xs: "20%", md: "100%" },
          bgcolor: "#264496",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderTopRightRadius: { xs: "0", md: "50px" },
          borderBottomRightRadius: { xs: "0", md: "50px" },
        }}
      >
        <Box
          sx={{
            display: { xs: "flex", md: "block" },
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: { xs: "40%", md: "100%" },
            }}
          >
            <img src={logoIMC} alt="logo" width={"100%"} />
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "block" },

              justifyContent: { xs: "center", md: "left" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Nunito, sans-serif",
                fontSize: { xs: "40px", md: "75px" },
                fontWeight: "900",
                color: "#FFFFFF",
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Servicios
            </Typography>
            <Typography
              sx={{
                fontFamily: "Nunito, sans-serif",
                fontSize: { xs: "40px", md: "75px" },
                fontWeight: "900",
                color: "#FCC916",
                marginTop: { xs: "0px", md: "-40px" },
                paddingLeft: { xs: "10px", md: "0px" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              IMC
            </Typography>
          </Box>
        </Box>
      </Paper>
      <Paper
        sx={{
          width: { xs: "100%", md: "70%" },
          height: "100%",
          display: "flex",
          justifyContent: "center",
          boxShadow: "none",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Nunito, sans-serif",
            fontSize: { xs: "60px", md: "113px" },
            fontWeight: "900",
            color: "#264496",
            textAlign: { xs: "center", md: "left" },
            pl: { xs: 0, md: 12 },
            pr: { xs: 0, md: 12 },
          }}
        >
          Login
        </Typography>
        <Typography
          sx={{
            fontFamily: "Nunito, sans-serif",
            fontSize: { xs: "16px", md: "20px" },
            fontWeight: "500",
            color: "#B4B4B4",
            textAlign: { xs: "center", md: "left" },
            pl: { xs: 0, md: 12 },
            pr: { xs: 0, md: 12 },
          }}
        >
          Bienvenido al Sistema de Control de Servicios del Instituto Mar de
          Cortés. Por favor, accede a tu usuario.
        </Typography>
        <Box
          component="form"
          onSubmit={handleLogin}
          noValidate
          sx={{
            pl: { xs: 2, md: 12 },
            pr: { xs: 2, md: 12 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo eléctronico"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            sx={{
              fontFamily: "Nunito, sans-serif",
              borderRadius: "100px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "100px",
              },
              "& .MuiInputLabel-root": {
                borderRadius: "100px",
              },
              backgroundColor: "#D9D9D96B",
            }}
            onChange={({ target }) => setEmail(target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            sx={{
              borderRadius: "100px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "100px",
              },
              "& .MuiInputLabel-root": {
                borderRadius: "100px",
              },
              backgroundColor: "#D9D9D96B",
            }}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              fontSize: { xs: "16px", md: "20px" },
              width: { xs: "100%", md: "25%" },
              borderRadius: "100px",
              backgroundColor: "#264496",
              textTransform: "capitalize",
              fontFamily: "Nunito, sans-serif",
              fontWeight: "800",
            }}
          >
            Acceder
          </Button>
          <BasicAlert
            {...alertMessage}
            sx={{
              width: "100%",
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
}
