import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import bcrypt from "bcryptjs";
import jwt_decode from "jwt-decode";

const initialState = {
  id: "",
  user: "",
  email:"",
  login: false,
  type: {},
  uuid: "",
  error: false,
  isLoading: false,
};

export const loginAsync = createAsyncThunk(
  "auth/loginAsync",
  async (payload) => {
    const payloadHashed = {
      user: payload.user,
      password: bcrypt.hashSync(String(payload.password), 10),
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_CORE_IMC + "/login",
        payloadHashed
      );

      const token = response.data.token;
      const decodedToken = jwt_decode(token);

      localStorage.setItem("token", token);

      return {
        ...response.data,
        decodedToken: decodedToken,
      };
    } catch (error) {
      return { error: true };
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    logOut: (state, action) => {
      localStorage.removeItem("token");
      state.id = "";
      state.user = "";
      state.login = false;
      state.type = {};
    },
    reloadLogin: (state, action) => {
      const token = localStorage.getItem("token")
        ? localStorage.getItem("token")
        : null;
      if (token !== null) {
        const decodedToken = jwt_decode(token);
        state.user = decodedToken.sub.user.split("@")[0];
        state.login = true;
        state.type = decodedToken.sub.type;
        state.uuid = decodedToken.sub.uuid;
      } else {
        state.user = "";
        state.login = false;
        state.type = {};
        state.uuid = "";
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        if (action.payload.error === false) {
          state.user = action.payload.decodedToken.sub.user;
          state.login = true;
          state.type = action.payload.decodedToken.sub.type;
          state.error = false;
          state.isLoading = false;
          state.uuid = action.payload.decodedToken.sub.uuid;
        } else {
          state.error = true;
          state.isLoading = false;
        }
      })
      .addCase(loginAsync.rejected, (state) => {
        state.isLoading = false;

      })
      ;
  },
});

export const { logOut, reloadLogin } = authSlice.actions;

export default authSlice.reducer;





