import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function BasicCard(data) {
  return (
    <Card sx={{ minWidth: 275, boxShadow: 4 }}>
      <CardContent>
        <Typography
          sx={{ fontFamily: "Nunito, sans-serif", fontWeight: "600" }}
          variant="h5"
          component="div"
        >
          {data.title}
        </Typography>
        <Box>{data.icon}</Box>

        <Typography sx={{ fontFamily: "Nunito, sans-serif" }} variant="body2">
          {data.description}
        </Typography>
      </CardContent>
    </Card>
  );
}
