import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  data: [
    {
      name: "",
      secondName: "",
      lastName1: "",
      lastName2: "",
      birthDate: "",
      pathPhoto: "",
    },
  ],
  month: new Date().getMonth() + 1,
  isLoading: false,
  error: [
    {
      message: "",
      code: "",
    },
  ],
};

export const getBirthday = createAsyncThunk(
  "birthday/getBirthday",
  async (month) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.get(
        process.env.REACT_APP_API_CORE_IMC + "/birthday/" + month,
        { headers: headers }
      );
      return response.data.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const birthdaySlice = createSlice({
  name: "birthday",
  initialState: initialState,
  reducers: {
    updateMonth: (state, action) => {
      state.month = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBirthday.pending, (state) => {
        state.error = [];
        state.isLoading = true;
      })
      .addCase(getBirthday.fulfilled, (state, action) => {
        state.data = action.payload;
        state.error = [];
        state.isLoading = false;
      })
      .addCase(getBirthday.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { updateMonth } = birthdaySlice.actions;

export default birthdaySlice.reducer;
