import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Table4Rows from "../../components/Table4Rows";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import NavBar from "../../components/NavBar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Back from "../../components/Back";
import {
  getListHoursScholarship,
  filterScholarshipService,
  updateSemesterScholarshipServiceFilter,
  getPropety,
  updateProperties,
} from "../../features/scholarshipService/scholarshipServiceSlice";
import Loader from "../../components/Loader";
import SearchIcon from "@mui/icons-material/Search";
import BasicTextFields from "../../components/BasicTextFields";
import InputAdornment from "@mui/material/InputAdornment";
import BasicSelect from "../../components/BasicSelect";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SettingsIcon from "@mui/icons-material/Settings";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BasicDatePicker from "../../components/BasicDatePicker";
import BasicAlert from "../../components/BasicAlert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function SearchStudents() {
  const isLoading = useSelector((state) => state.scholarshipService.isLoading);
  const studentsFilter = useSelector(
    (state) => state.scholarshipService.filter
  );
  const semesterFilter = useSelector(
    (state) => state.scholarshipService.semesterFilter
  );
  const propertiesState = useSelector(
    (state) => state.scholarshipService.properties
  );
  const [dataSearch, setDataSearch] = React.useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getListHoursScholarship());
      await dispatch(filterScholarshipService(""));
      await dispatch(updateSemesterScholarshipServiceFilter(0));
      await dispatch(getPropety());
    };

    fetchData();
  }, [dispatch]);

  let dataPagos = [];

  const headers = [
    studentsFilter.length
      ? {
          id: 1,
          title1: "Alumno",
          title2: "Semestre",
          title3: "Horas",
          title5: "Status",
        }
      : {
          id: 1,
          title1: "",
          title2: "No hay datos disponibles",
          title3: "",
        },
  ];

  for (let index = 0; index < studentsFilter.length; index++) {
    dataPagos.push({
      data1: {
        colorTableCellFirst:
          studentsFilter[index].status === "Excelente"
            ? "#0288d1"
            : studentsFilter[index].status === "Bueno"
            ? "#2e7d32"
            : studentsFilter[index].status === "Regular"
            ? "#ed6c02"
            : "#d32f2f",
        type: "text",
        data:
          studentsFilter[index].name +
          (studentsFilter[index].secondName !== null
            ? " " + studentsFilter[index].secondName + " "
            : " ") +
          studentsFilter[index].lastName1 +
          " " +
          studentsFilter[index].lastName2,
      },
      data2: {
        type: "text",
        data: studentsFilter[index].semester,
      },
      data3: {
        type: "text",
        data:
          studentsFilter[index].totalHours +
          " / " +
          studentsFilter[index].daysCurrent,
      },
      data4: {
        type: "chip",
        data: studentsFilter[index].status,
        color:
          studentsFilter[index].status === "Excelente"
            ? "info"
            : studentsFilter[index].status === "Bueno"
            ? "success"
            : studentsFilter[index].status === "Regular"
            ? "warning"
            : "error",
      },
      id: studentsFilter[index].idExternalUser,
    });
  }

  const data = [
    {
      value: 0,
      label: "Todos",
    },
    {
      value: 1,
      label: "Primer semestre",
    },
    {
      value: 2,
      label: "Segundo semestre",
    },
    {
      value: 3,
      label: "Tercer semestre",
    },
    {
      value: 4,
      label: "Cuarto semestre",
    },
    {
      value: 5,
      label: "Quinto semestre",
    },
    {
      value: 6,
      label: "Sexto semestre",
    },
  ];

  const handleChange = async (newValue) => {
    if (newValue.target.name === "search") {
      setDataSearch(newValue.target.value);
      dispatch(filterScholarshipService(newValue.target.value));
    } else if (newValue.target.name === "semester") {
      dispatch(updateSemesterScholarshipServiceFilter(newValue.target.value));
      setDataSearch("");
    }
  };

  const handleClose = () => {
    //setHours("")
    setOpen(false);
  };

  const handleUpdateProperties = async () => {
    await dispatch(
      updateProperties({
        initDate: properties.initDate,
        finishDate: properties.finishDate,
        specialHours: properties.hours,
        excellentRange: properties.excellentRange,
        goodRange: properties.goodRange,
        regularRange: properties.regularRange,
      })
    )
      .then(async (result) => {
        if (result.error) {
          setStatusDisplayBasicAlert(true);
        } else {
          setStatusDisplayBasicAlert(false);
          await dispatch(getListHoursScholarship());
        }
        // Si la actualización es exitosa, puedes limpiar el estado y cerrar el diálogo

        setOpen(false);
      })
      .catch((error) => {
        setStatusDisplayBasicAlert(true);
      });
  };

  const [properties, setProperties] = React.useState({});

  useEffect(() => {
    if (Array.isArray(propertiesState) && propertiesState.length > 0) {
      setProperties({
        initDate: propertiesState[0].init_date || "",
        finishDate: propertiesState[0].finish_date || "",
        hours: parseInt(propertiesState[0]?.special_hours) || 0,
        excellentRange: parseInt(propertiesState[0]?.excellent_range) || 0,
        goodRange: parseInt(propertiesState[0]?.good_range) || 0,
        regularRange: parseInt(propertiesState[0]?.regular_range) || 0,
      });
    }
  }, [propertiesState]);

  const onChangeDatePicker = (data) => {
    setProperties({
      ...properties,
      [data.name]: data.date,
    });
  };

  const handleChangeInput = (e) => {
    if (
      e.target.name === "hours" ||
      e.target.name === "excellentRange" ||
      e.target.name === "goodRange" ||
      e.target.name === "regularRange"
    ) {
      const numericValue = e.target.value.replace(/[^0-9]/g, "");
      setProperties({
        ...properties,
        [e.target.name]: numericValue,
      });
    } else {
      setProperties({
        ...properties,
        [e.target.name]: e.target.value,
      });
    }
  };

  const [statusDisplayBasicAlert, setStatusDisplayBasicAlert] =
    React.useState(false);

  return (
    <Box>
      <Loader loading={isLoading} />
      <Container>
        <NavBar />
        <Back ruta={"/admin"} />
        <Box>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Modificar propiedades</DialogTitle>
            <DialogContent>
              <Box sx={{ flexGrow: 1, m: 4 }}>
                <Grid spacing={2}>
                  <Grid xs={12} sx={{ my: 1 }}>
                    <BasicDatePicker
                      name="initDate"
                      onChangeDatePicker={onChangeDatePicker}
                      label={"Fecha de inicio"}
                      defaultValue={properties.initDate}
                    ></BasicDatePicker>
                  </Grid>
                  <Grid xs={12} sx={{ my: 1 }}>
                    <BasicDatePicker
                      name="finishDate"
                      onChangeDatePicker={onChangeDatePicker}
                      label={"Fecha de cierre"}
                      defaultValue={properties.finishDate}
                    ></BasicDatePicker>
                  </Grid>
                  <Grid xs={12}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <AccessTimeIcon />
                        </InputAdornment>
                      }
                      label="Horas especiales"
                      onChange={handleChangeInput}
                      name="hours"
                      value={properties.hours}
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <AccessTimeIcon />
                        </InputAdornment>
                      }
                      label="Rango de excelencia"
                      onChange={handleChangeInput}
                      name="excellentRange"
                      value={properties.excellentRange}
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <AccessTimeIcon />
                        </InputAdornment>
                      }
                      label="Rango Bueno"
                      onChange={handleChangeInput}
                      name="goodRange"
                      value={properties.goodRange}
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <AccessTimeIcon />
                        </InputAdornment>
                      }
                      label="Rango Regular"
                      onChange={handleChangeInput}
                      name="regularRange"
                      value={properties.regularRange}
                    ></BasicTextFields>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="error" onClick={handleClose}>
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleUpdateProperties}
              >
                Agregar
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        <Box>
          <Box sx={{ mb: 3 }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 28, md: 36 },
                color: "#264496",
                fontFamily: "Nunito, sans-serif",
              }}
            >
              Sistema Becario
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <BasicTextFields
                  startAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  label="Búsqueda"
                  onChange={handleChange}
                  name="search"
                  value={dataSearch}
                ></BasicTextFields>
              </Grid>
              <Grid item xs={4}>
                <BasicSelect
                  onChange={handleChange}
                  name="semester"
                  data={data}
                  label="Semestre"
                  value={semesterFilter}
                ></BasicSelect>
              </Grid>
              <Grid item xs={2}>
                <Button
                  sx={{ mt: 2 }}
                  variant="contained"
                  color="success"
                  startIcon={<SettingsIcon />}
                  onClick={() => setOpen(true)}
                >
                  Configuración
                </Button>
              </Grid>
              <BasicAlert
                display={statusDisplayBasicAlert}
                severity="error"
                label="Error al actualizar propiedades"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setStatusDisplayBasicAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              />
            </Grid>
          </Box>

          <Table4Rows admin={true} data={dataPagos} headers={headers} />
        </Box>
      </Container>
    </Box>
  );
}

export default SearchStudents;
