import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import NavBar from "../../components/NavBar";
import Back from "../../components/Back";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getGeneralDataUserByIdExternalUser,
} from "../../features/generalDataUsers/generalDataUsersSlice";
import { getCatalogByType } from "../../features/catalogs/catalogsSlice";
import Loader from "../../components/Loader";
import BasicAlert from "../../components/BasicAlert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
} from "@mui/material";
import axios from "axios";

const PermissionScreen = () => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.catalogs.data);

  const location = useLocation();
  const path = location.pathname;
  const segments = path.split("/");
  segments.pop();
  const uuid = path.split("/")[3];
  const beforeUrl = segments.join("/");
  const [loading, setLoading] = useState(true);

  const [checkedPermissions, setCheckedPermissions] = useState({});
  const [displayBasicAlertSuccess, setDisplayBasicAlertSuccess] =
    React.useState(false);
  const [statusDisplayBasicAlert, setStatusDisplayBasicAlert] =
    React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!uuid.includes("agregar")) {
        await dispatch(getGeneralDataUserByIdExternalUser(uuid));
        await dispatch(getCatalogByType("permisos"));
        const token = localStorage.getItem("token");
        const headers = { Authorization: "Bearer " + token };
        const response = await axios.get(
          process.env.REACT_APP_API_CORE_IMC +
            `/externalUsers/getPermissionsByIdExternalUser/` +
            uuid,
          { headers: headers }
        );
        const type = JSON.parse(response.data.data.data.type);

        setCheckedPermissions(type || {});
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, uuid]);

  const handleCheckboxChange = (permissionDescription) => {
    // Actualizar el estado de los permisos marcados
    setCheckedPermissions((prevPermissions) => ({
      ...prevPermissions,
      [permissionDescription]: !prevPermissions[permissionDescription],
    }));
  };

  const handleSavePermissions = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };

      const response = await axios.post(
        process.env.REACT_APP_API_CORE_IMC + "/externalUsers/updatePermissions",
        {
          idExternalUser: uuid,
          permissions: checkedPermissions,
        },
        { headers }
      );

      setDisplayBasicAlertSuccess(true);

      setStatusDisplayBasicAlert(response.data.status);
    } catch (error) {
      setDisplayBasicAlertSuccess(true);
      setStatusDisplayBasicAlert(false);

      console.error("Error al actualizar permisos:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Loader loading={loading} />

      <Container>
        <NavBar />
        <Box>
          <Back ruta={beforeUrl} />
          <Box sx={{ mb: 3 }}>
            <Typography
              sx={{ fontWeight: "bold", fontSize: { xs: 28, md: 36 } }}
            >
              Actualización de permisos
            </Typography>
            <Grid container spacing={2}>
              {permissions.map((permission) => (
                <Grid item xs={12} key={permission.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          checkedPermissions[permission.description] || false
                        }
                        onChange={() =>
                          handleCheckboxChange(permission.description)
                        }
                      />
                    }
                    label={permission.description}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSavePermissions}
            disabled={loading}
          >
            Guardar
          </Button>
        </Box>
      </Container>
      <Box sx={{ position: "fixed", bottom: 0, left: 0, width: "100%", p: 2 }}>
        <BasicAlert
          display={displayBasicAlertSuccess}
          severity={statusDisplayBasicAlert ? "success" : "error"}
          label={
            statusDisplayBasicAlert
              ? "Datos actualizados"
              : "Error al actualizar datos"
          }
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setDisplayBasicAlertSuccess(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        />
      </Box>
    </Box>
  );
};

export default PermissionScreen;
