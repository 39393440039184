import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import NavBar from "../../components/NavBar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getBirthday,
  updateMonth,
} from "../../features/birthday/birthdaySlice";
import { useDispatch } from "react-redux";
import BasicSelect from "../../components/BasicSelect";
import { dataMonths } from "../../helpers/catalogs";
import Back from "../../components/Back";
import { useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Loader from "../../components/Loader";

function Birthday() {
  let location = useLocation();
  const path = location.pathname;
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.birthday.isLoading);
  const birthdayData = useSelector((state) => state.birthday.data);
  const month = useSelector((state) => state.birthday.month);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getBirthday(month));
      } catch (error) {
        console.error("Error al realizar operaciones asíncronas:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleChange = async (value) => {
    if (value !== null) {
      await dispatch(updateMonth(value.target.value));
      await dispatch(getBirthday(value.target.value));
    }
  };

  return (
    <Container>
      <Loader loading={isLoading} />
      <NavBar />
      <Box sx={{ mt: -4 }}>
        <Back ruta={"/" + path.split("/")[1]} />
      </Box>
      <Box>
        <Box sx={{ mb: 3 }}>
          <Typography
            sx={{
              mb: 4,
              fontWeight: "bold",
              fontSize: { xs: 28, md: 36 },
              color: "#264496",
              fontFamily: "Nunito, sans-serif",
            }}
          >
            Cumpleaños
          </Typography>
        </Box>
      </Box>
      <Box sx={{ maxWidth: "500px", margin: "auto" }}>
        <Box sx={{ mr: 1 }}>
          <BasicSelect
            value={month}
            onChange={handleChange}
            name="type"
            data={dataMonths}
            label="Mes"
          ></BasicSelect>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          {birthdayData.map((birthday, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card sx={{ maxWidth: 345 }}>
                <CardHeader
                  title={
                    <>
                      {birthday.name} {birthday.secondName}
                      <br />
                      {birthday.lastName1} {birthday.lastName2}
                    </>
                  }
                  subheader={`${birthday.birthDate}`}
                />

                <CardMedia
                  component="img"
                  height="280"
                  image={`${birthday.pathPhoto}`}
                  alt="Foto cumpleaños"
                />
                <CardContent>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                  ></Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}

export default Birthday;
