import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

export default function HorizontalStepper({ activeStep, steps }) {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        sx={{
          '& .MuiStepIcon-root': {
            color: 'lightgray', // color predeterminado
            '&.Mui-active': { color: '#1976d2' }, // color del paso activo
            '&.Mui-completed': { color: '#019645' }, // color del paso completado
          },
          '& .MuiStepLabel-label': {
            color: 'lightgray', // color predeterminado
            '&.Mui-active': { color: '#1976d2' }, // color del texto del paso activo
            '&.Mui-completed': { color: '#019645' }, // color del texto del paso completado
          },
        }}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel sx={{fontFamily: "Nunito, sans-serif"}}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
