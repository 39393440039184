import * as React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export default function BasicAlert({ display, severity, label, action }) {
  return (
    <Stack
      sx={{ width: "100%", display: display ? "block" : "none" }}
      spacing={2}
    >
      <Alert  variant="filled" severity={severity} action={action}>
        {label}
      </Alert>
    </Stack>
  );
}
