import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import NavBar from "../../components/NavBar";
import BasicCard from "../../components/BasicCard";
import Grid from "@mui/material/Unstable_Grid2";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CakeIcon from "@mui/icons-material/Cake";

function AdminPagos() {
  const history = useNavigate();
  let auth = useSelector((state) => state.auth);
  let type = null;
  if (auth.login === true) {
    type = JSON.parse(auth.type);
  }

  const goTo = async (data) => {
    history("/admin/" + data);
  };

  const tienePermisos = () => {
    if (type) {
      let totalPermisos = Object.values(type).length - 1;
      let permisosFalse = 0;
      Object.values(type).map((value) =>
        value === false ? permisosFalse++ : ""
      );
      return permisosFalse === totalPermisos ? false : true;
    }
    return false;
  };

  return (
    <Container>
      <NavBar />
      <Box>
        <Box sx={{ mb: 3 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 28, md: 36 },
              color: "#264496",
              fontFamily: "Nunito, sans-serif",
            }}
          >
            Panel de Control Servicios IMC
          </Typography>
          <Box sx={{ flexGrow: 1, mt: 5 }}>
            {tienePermisos() === false ? (
              <Box sx={{ textAlign: "center" }}>
                <Typography sx={{ color: "#e53935", fontSize: "20px" }}>
                  No tienes permisos, contacta al área de sistemas.
                </Typography>
              </Box>
            ) : null}
            <Grid container justifyContent="center" spacing={2}>
              {type?.adminPagos === true && (
                <Grid xs={12} md={3}>
                  <a onClick={() => goTo("pagos")}>
                    <BasicCard
                      title="Pagos"
                      description=""
                      icon={
                        <MonetizationOnIcon
                          color="#969FAA"
                          fontSize="large"
                          sx={{ my: 2 }}
                        />
                      }
                    ></BasicCard>
                  </a>
                </Grid>
              )}
              {type?.adminPagos === true && (
                <Grid xs={12} md={3}>
                  <a onClick={() => goTo("montos")}>
                    <BasicCard
                      title="Montos"
                      description=""
                      icon={
                        <PointOfSaleIcon
                          color="#969FAA"
                          fontSize="large"
                          sx={{ my: 2 }}
                        />
                      }
                    ></BasicCard>
                  </a>
                </Grid>
              )}
              {type?.adminDirectorio === true && (
                <Grid xs={12} md={3}>
                  <a onClick={() => goTo("directorio")}>
                    <BasicCard
                      title="Directorio"
                      description=""
                      icon={
                        <PeopleAltIcon
                          color="#969FAA"
                          fontSize="large"
                          sx={{ my: 2 }}
                        />
                      }
                    ></BasicCard>
                  </a>
                </Grid>
              )}
              {type?.adminDerechos === true && (
                <Grid xs={12} md={3}>
                  <a onClick={() => goTo("derechos")}>
                    <BasicCard
                      title="Derechos"
                      description=""
                      icon={
                        <AdminPanelSettingsIcon
                          color="#969FAA"
                          fontSize="large"
                          sx={{ my: 2 }}
                        />
                      }
                    ></BasicCard>
                  </a>
                </Grid>
              )}

              {type?.adminServicioBecario === true && (
                <Grid xs={12} md={3}>
                  <a onClick={() => goTo("servicio-becario")}>
                    <BasicCard
                      title="Servicio Becario"
                      description=""
                      icon={
                        <CastForEducationIcon
                          color="#969FAA"
                          fontSize="large"
                          sx={{ my: 2 }}
                        />
                      }
                    ></BasicCard>
                  </a>
                </Grid>
              )}
              {type?.adminPdfs === true && (
                <Grid xs={12} md={3}>
                  <a onClick={() => goTo("generador-pdf")}>
                    <BasicCard
                      title="Generador de PDF"
                      description=""
                      icon={
                        <PictureAsPdfIcon
                          color="#969FAA"
                          fontSize="large"
                          sx={{ my: 2 }}
                        />
                      }
                    ></BasicCard>
                  </a>
                </Grid>
              )}
              {type?.adminBirthday === true && (
                <Grid xs={12} md={3}>
                  <a onClick={() => goTo("birthday")}>
                    <BasicCard
                      title="Cumpleaños"
                      description=""
                      icon={
                        <CakeIcon
                          color="#969FAA"
                          fontSize="large"
                          sx={{ my: 2 }}
                        />
                      }
                    ></BasicCard>
                  </a>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default AdminPagos;
