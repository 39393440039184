import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from "react-router-dom";

export default function IconLabelButtons({ruta}) {
    const history = useNavigate();

  return (
    <Stack direction="row" spacing={2}>
      <Button sx={{color:"#1a75ce"}} onClick={() => history(ruta)} variant="text" startIcon={<KeyboardBackspaceIcon sx={{color:"#1a75ce"}} />}>
        Regresar
      </Button>
    </Stack>
  );
}