import { Box, Grid } from "@mui/material";
import { useState } from "react";
import SyncLoader from "react-spinners/SyncLoader";

export default function Loader({ loading }) {
  return (
    <Box
      sx={{
        display: loading ? "block" : "none",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,

        zIndex: "9999",
        position: "fixed",
        backgroundColor: "rgb(168,168,168,0.6)",
      }}
    >
      <Grid
        sx={{ height: "100%", width: "100%" }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <SyncLoader
          color={"#1a75ce"}
          loading={loading}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </Grid>
    </Box>
  );
}
