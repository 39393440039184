import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Table4Rows from "../../components/Table4Rows";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import NavBar from "../../components/NavBar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Back from "../../components/Back";
import {
  getDataUserScholarship,
  filterScholarshipService,
  deleteHoursScholarship,
  insertHoursScholarship
} from "../../features/scholarshipService/scholarshipServiceSlice";
import Loader from "../../components/Loader";
import { useLocation } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import FloatingButton from "../../components/FloatingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import BasicTextFields from "../../components/BasicTextFields";
import InputAdornment from "@mui/material/InputAdornment";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const moment = require("moment");

function AdminServicioBecarioDetail() {
  let location = useLocation();
  const path = location.pathname;
  const uuid = path.split("/")[3];
  const auth = useSelector((state) => state.auth);
  const user=auth.user
  const isLoading = useSelector((state) => state.scholarshipService.isLoading);
  const studentsFilter = useSelector(
    (state) => state.scholarshipService.filter
  );
  const [open, setOpen] = React.useState(false);
  const [hours, setHours] = React.useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getDataUserScholarship(uuid));
      await dispatch(filterScholarshipService(""));
      //await dispatch(updateSemesterScholarshipServiceFilter());
    };
  
    fetchData();
  
  }, [dispatch, uuid]);

  
  

  let dataPagos = [];

  const headers = [
    studentsFilter.length
      ? {
          id: 1,
          title1: "Alumno",
          title2: "Semestre",
          title3: "Horas",
          title5: "Status",
        }
      : {
          id: 1,
          title1: "",
          title2: "No hay datos disponibles",
          title3: "",
        },
  ];

  for (let index = 0; index < studentsFilter.length; index++) {
    dataPagos.push({
      data1: {
        colorTableCellFirst:
          studentsFilter[index].status === "Excelente"
            ? "#0288d1"
            : studentsFilter[index].status === "Bueno"
            ? "#2e7d32"
            : studentsFilter[index].status === "Regular"
            ? "#ed6c02"
            : "#d32f2f",
        type: "text",
        data:
          studentsFilter[index].name +
          (studentsFilter[index].secondName !== null
            ? " " + studentsFilter[index].secondName + " "
            : " ") +
          studentsFilter[index].lastName1 +
          " " +
          studentsFilter[index].lastName2,
      },
      data2: {
        type: "text",
        data: studentsFilter[index].semester,
      },
      data3: {
        type: "text",
        data:
          studentsFilter[index].totalHours +
          " / " +
          studentsFilter[index].daysCurrent,
      },
      data4: {
        type: "chip",
        data: studentsFilter[index].status,
        color:
          studentsFilter[index].status === "Excelente"
            ? "info"
            : studentsFilter[index].status === "Bueno"
            ? "success"
            : studentsFilter[index].status === "Regular"
            ? "warning"
            : "error",
      },

    });
  }

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClickDelete = async (idExternalUser, idHour) => {
    await dispatch(
      deleteHoursScholarship({ idExternalUser: idExternalUser, idHour: idHour })
    );
    await dispatch(getDataUserScholarship(uuid));
  };

  const handleClickOpen = () => {
    setHours("")
    setOpen(true);
  };

  const handleClose = () => {
    setHours("")
    setOpen(false);
  };

  const handleChange = (e) => {
    if (
      e.target.name === "hours" 
    ) {
      const numericValue = e.target.value.replace(/[^0-9]/g, "");
      setHours(
        numericValue,
      );
    }
  };

  const handleInsertHours = async () => {
    await dispatch(insertHoursScholarship({idExternalUser:uuid,hour:hours,user}))
    await dispatch(getDataUserScholarship(uuid));
    setHours("");
    setOpen(false);
  };

  return (
    <Box>
      <Loader loading={isLoading} />
      <Container>
        <NavBar />
        <FloatingButton onClick={handleClickOpen}></FloatingButton>
        <Box>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Agregar horas</DialogTitle>
            <DialogContent>
              <Box sx={{ flexGrow: 1, m: 4 }}>
                <Grid spacing={2}>
                  <Grid xs={12} sx={{ my: 2 }}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <AccessTimeIcon />
                        </InputAdornment>
                      }
                      label="Número de horas"
                      onChange={handleChange}
                      name="hours"
                      value={hours}
                    ></BasicTextFields>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="error" onClick={handleClose}>
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleInsertHours}
              >
                Agregar
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        <Back ruta={"/admin/servicio-becario"} />
        <Box>
          <Box sx={{ mb: 3 }}>
            <Typography
              sx={{ fontWeight: "bold", fontSize: { xs: 28, md: 36 } }}
            >
              Sistema Becario
            </Typography>
          </Box>

          <Table4Rows admin={true} data={dataPagos} headers={headers} />
        </Box>
        <Box>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h6">
                Historial de horas ingresadas
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                {studentsFilter[0]?.hourHistory
                  ? studentsFilter[0].hourHistory.map((item,key) =>
                      item.action === "INSERT" ? (
                        <Grid key={"Grid-"+key} item xs={3}>
                          <Card
                            sx={{
                              borderRight: "1px solid #ccc",
                            }}
                          >
                            <CardHeader
                              avatar={
                                <Avatar
                                  sx={{ bgcolor: "#2e7d32" }}
                                  aria-label="recipe"
                                >
                                  {item.hour}
                                </Avatar>
                              }
                              action={
                                <IconButton
                                  sx={{ color: red[500] }}
                                  aria-label="settings"
                                  onClick={() =>
                                    handleClickDelete(
                                      item.idExternalUser,
                                      item.id
                                    )
                                  }
                                >
                                  <CloseIcon />
                                </IconButton>
                              }
                              title={item.user.split('@')[0]}
                              subheader={moment(item.created_at).format(
                                "DD-MM-YYYY"
                              )}
                            />
                          </Card>
                        </Grid>
                      ) : null
                    )
                  : null}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <Typography variant="h6">
                Historial de horas eliminadas
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                {studentsFilter[0]?.hourHistory
                  ? studentsFilter[0].hourHistory.map((item,key) =>
                      item.action === "DELETE" ? (
                        <Grid key={"Grid-"+key} item xs={3}>
                          <Card
                            sx={{
                              borderRight: "1px solid #ccc",
                            }}
                          >
                            <CardHeader
                              avatar={
                                <Avatar
                                  sx={{ bgcolor: "#d32f2f" }}
                                  aria-label="recipe"
                                >
                                  {item.hour}
                                </Avatar>
                              }
                              title={item.user.split('@')[0]}
                              subheader={moment(item.created_at).format(
                                "DD-MM-YYYY"
                              )}
                            />
                          </Card>
                        </Grid>
                      ) : null
                    )
                  : null}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </Box>
  );
}

export default AdminServicioBecarioDetail;
