import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Grid from "@mui/material/Grid";

export default function RecipeReviewCard({ title, description, avatar,percentage }) {
  return (
    <Card>
      
        <CardContent sx={{ textAlign: "center",  backgroundColor:"#264496",color:"white"}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{fontSize:"20px", fontWeight:"600",textAlign:"center"}} variant="h5">{title}</Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" color="white">
            {description}
          </Typography>
        </CardContent>
     
    </Card>
  );
}
