import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

const style = {
    margin: 0,
    top: 'auto',
    right: 40,
    bottom: 40,
    left: 'auto',
    position: 'fixed',
};

export default function FloatingActionButtons({onClick}) {
  return (
    <Box sx={{ '& > :not(style)': { m: 1 } }}>
      <Fab onClick={onClick} sx={style} color="primary" aria-label="add">
        <AddIcon />
      </Fab>
    </Box>
  );
}