import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Table4Rows from "../../components/Table4Rows";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import NavBar from "../../components/NavBar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Back from "../../components/Back";
import { getGeneralDataListByStatus } from "../../features/generalDataUsers/generalDataUsersSlice";
import Loader from "../../components/Loader";
import SearchIcon from "@mui/icons-material/Search";
import BasicTextFields from "../../components/BasicTextFields";
import InputAdornment from "@mui/material/InputAdornment";
import {
  filterGeneralDataUsers,
  updateTypeFilter,
} from "../../features/generalDataUsers/generalDataUsersSlice";

import BasicSelect from "../../components/BasicSelect";
import Grid from "@mui/material/Grid";
import FloatingButton from "../../components/FloatingButton";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { status } from "../../helpers/catalogs";

function SearchStudents() {
  const isLoading = useSelector((state) => state.generalDataUsers.isLoading);
  const generalDataUsersFilter = useSelector(
    (state) => state.generalDataUsers.filter
  );

  const [dataSearch, setDataSearch] = React.useState("");
  const [selectedType, setSelectedType] = useState("Todos");
  const [selectedStatus, setSelectedStatus] = useState("1");

  const history = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getGeneralDataListByStatus(selectedStatus));
      await dispatch(filterGeneralDataUsers(""));
      await dispatch(updateTypeFilter(""));
    };
    fetchData();
  }, [dispatch]);

  let data = [];
  let headers = [];

  if (Array.isArray(generalDataUsersFilter)) {
    data = generalDataUsersFilter.map((generalDataUsers) => ({
      data1: {
        type: "text",
        data:
          generalDataUsers.name +
          (generalDataUsers.secondName !== null
            ? " " + generalDataUsers.secondName + " "
            : " ") +
          generalDataUsers.lastName1 +
          " " +
          generalDataUsers.lastName2,
      },
      data2: { type: "text", data: generalDataUsers.type },

      data3: { type: "text", data: generalDataUsers.phone },
      id: generalDataUsers.idExternalUser,
    }));

    headers = generalDataUsersFilter.length
    ? [
        {
          id: 1,
          title1: "Nombre",
          title2: "Tipo",
          title3: "Teléfono",
        },
      ]
    : [
        {
          id: 1,
          title1: "",
          title2: "No hay datos disponibles",
          title3: "",
        },
      ];
}

  const dataSelect = [
    {
      value: "Todos",
      label: "Todos",
    },
    {
      value: "Alumno",
      label: "Alumnos",
    },
    {
      value: "Docente",
      label: "Docentes",
    },
    {
      value: "Planta",
      label: "Planta",
    },
  ];

  //const actionsMultipleFloatingButton = [
  //{ icon: <PersonIcon />, name: "alumno" },
  //{ icon: <ManageAccountsIcon />, name: "personal-escolar" },
  //];

  const handleChange = async (newValue) => {
    if (newValue.target.name === "search") {
      await setDataSearch(newValue.target.value);
      await dispatch(filterGeneralDataUsers(newValue.target.value));
    } else if (newValue.target.name === "type") {
      await setDataSearch("");
      await dispatch(updateTypeFilter(newValue.target.value));
      await setSelectedType(newValue.target.value);
    }
    else if (newValue.target.name === "status") {
      await setSelectedStatus(newValue.target.value);
      await dispatch(getGeneralDataListByStatus(newValue.target.value));
       await setDataSearch("");
       await dispatch(updateTypeFilter(""));
       await setSelectedType("Todos");
    }
  };

  const handleClhandleClickFloatingButtonickOpen = () => {
    history(path + "/agregar");
  };

  /*
  <MultipleFloatingButton
onClick={handleClhandleClickFloatingButtonickOpen}
actions={actionsMultipleFloatingButton}
></MultipleFloatingButton>
*/

  return (
    <Box>
      <Loader loading={isLoading} />
      <Container>
        <NavBar />
        <FloatingButton
          onClick={handleClhandleClickFloatingButtonickOpen}
        ></FloatingButton>
        <Back ruta={"/admin"} />
        <Box>
          <Box sx={{ mb: 3 }}>
            <Typography
              sx={{ fontWeight: "bold", fontSize: { xs: 28, md: 36 },                color: "#264496",
              fontFamily: "Nunito, sans-serif", }}
            >
              Directorio
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <BasicTextFields
                  startAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  label="Búsqueda"
                  onChange={handleChange}
                  name="search"
                  value={dataSearch}
                ></BasicTextFields>
              </Grid>
              <Grid item xs={4}>
                <BasicSelect
                  onChange={handleChange}
                  name="type"
                  data={dataSelect}
                  label="Tipo"
                  value={selectedType}
                ></BasicSelect>
              </Grid>
              <Grid item xs={2}>
                <BasicSelect
                  onChange={handleChange}
                  name="status"
                  data={status}
                  label="Estado"
                 value={selectedStatus}
                ></BasicSelect>
              </Grid>
            </Grid>
          </Box>
          <Table4Rows
            admin={true}
            data={data}
            headers={headers}
            routeLink={""}
          />
        </Box>
      </Container>
    </Box>
  );
}

export default SearchStudents;
