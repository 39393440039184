import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import unorm from "unorm";

const initialState = {
  data: [
    {
      id: "",
      idExternalUser: "",
      name: "",
      secondName: "",
      lastName1: "",
      lastName2: "",
      totalHours: "",
      semester: "",
      status: "",
      daysCurrent: "",
      hourHistory: "",
    },
  ],
  properties: [],
  isLoading: false,
  filter: [],
  semesterFilter: 0,
  error: [
    {
      message: "",
      code: "",
    },
  ],
};

export const getListHoursScholarship = createAsyncThunk(
  "scolarshipService/getListHoursScholarship",
  async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.get(
        process.env.REACT_APP_API_CORE_IMC + "/scholarshipService/getListHours",
        { headers: headers }
      );

      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const getDataUserScholarship = createAsyncThunk(
  "scolarshipService/getDataUserScholarship",
  async (idExternalUser) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.get(
        process.env.REACT_APP_API_CORE_IMC +
          "/scholarshipService/getDataUserScholarship/" +
          idExternalUser,
        { headers: headers }
      );
      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const deleteHoursScholarship = createAsyncThunk(
  "scolarshipService/deleteHoursScholarship",
  async ({ idExternalUser, idHour }) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.post(
        process.env.REACT_APP_API_CORE_IMC +
          "/scholarshipService/deleteHoursScholarship",
        { idExternalUser, idHour },
        { headers: headers }
      );
      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const insertHoursScholarship = createAsyncThunk(
  "scolarshipService/insertHoursScholarship",
  async ({ idExternalUser, hour, user }) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.post(
        process.env.REACT_APP_API_CORE_IMC + "/scholarshipService/insertHour",
        { idExternalUser, hour, user },
        { headers: headers }
      );
      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const getPropety = createAsyncThunk(
  "scolarshipService/getPropety",
  async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.get(
        process.env.REACT_APP_API_CORE_IMC + "/scholarshipService/getPropety",
        { headers: headers }
      );
      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const updateProperties = createAsyncThunk(
  "scolarshipService/updateProperties",
  async ({
    initDate,
    finishDate,
    specialHours,
    excellentRange,
    goodRange,
    regularRange,
  }) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.post(
        process.env.REACT_APP_API_CORE_IMC +
          "/scholarshipService/updateProperties",
        {
          initDate,
          finishDate,
          specialHours,
          excellentRange,
          goodRange,
          regularRange,
        },
        { headers: headers }
      );
      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const scholarshipServiceSlice = createSlice({
  name: "scholarshipService",
  initialState: initialState,
  reducers: {
    updateSemesterScholarshipServiceFilter: (state, action) => {
      state.semesterFilter = action.payload;
      state.filterStudents = state.data;

      if (action.payload !== 0) {
        const filtered = state.data.filter(function (el) {
          return el.semester === action.payload;
        });

        if (filtered) {
          state.filter = filtered;
        }
      } else {
        state.filter = state.data;
      }
    },
    filterScholarshipService: (state, action) => {
      const { payload } = action;
      const { data, semesterFilter } = state;

      // Función para normalizar y convertir a minúsculas
      const normalizeAndLowercase = (str) => unorm.nfkd(str).toLowerCase();

      if (payload !== "") {
        const filteredData = data.filter((student) => {
          const fullName = `${student.name} ${student.secondName || ""} ${
            student.lastName1
          } ${student.lastName2}`;
          return (
            (semesterFilter === 0 || student.semester === semesterFilter) &&
            (normalizeAndLowercase(student.name).includes(
              normalizeAndLowercase(payload)
            ) ||
              normalizeAndLowercase(student.lastName1).includes(
                normalizeAndLowercase(payload)
              ) ||
              normalizeAndLowercase(student.lastName2).includes(
                normalizeAndLowercase(payload)
              ) ||
              normalizeAndLowercase(String(student.reference)).includes(
                normalizeAndLowercase(payload)
              ) ||
              normalizeAndLowercase(
                student.name + " " + student.lastName1
              ).includes(normalizeAndLowercase(payload)) ||
              normalizeAndLowercase(
                student.name + " " + student.lastName1 + " " + student.lastName2
              ).includes(normalizeAndLowercase(payload)) ||
              normalizeAndLowercase(fullName).includes(
                normalizeAndLowercase(payload)
              ))
          );
        });

        state.filter = filteredData;
      } else {
        if (semesterFilter === 0) {
          state.filter = data;
        } else {
          const filteredData = data.filter(
            (student) => student.semester === semesterFilter
          );
          state.filter = filteredData;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListHoursScholarship.pending, (state) => {
        state.error = [];
        state.isLoading = true;
      })
      .addCase(getListHoursScholarship.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.filter = action.payload.data;
        state.error = [];
        state.isLoading = false;
      })
      .addCase(getListHoursScholarship.rejected, (state, action) => {
        state.error = {
          code: action.error.code,
          message: action.error.message,
        };
        state.isLoading = false;
      })
      .addCase(getDataUserScholarship.pending, (state) => {
        state.error = [];
        state.isLoading = true;
      })
      .addCase(getDataUserScholarship.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.filter = action.payload.data;
        state.error = [];
        state.isLoading = false;
      })
      .addCase(getDataUserScholarship.rejected, (state, action) => {
        state.error = {
          code: action.error.code,
          message: action.error.message,
        };
        state.isLoading = false;
      })
      .addCase(deleteHoursScholarship.pending, (state) => {
        state.error = [];
        state.isLoading = true;
      })
      .addCase(deleteHoursScholarship.fulfilled, (state, action) => {
        state.error = [];
        state.isLoading = false;
      })
      .addCase(deleteHoursScholarship.rejected, (state, action) => {
        state.error = {
          code: action.error.code,
          message: action.error.message,
        };
        state.isLoading = false;
      })
      .addCase(insertHoursScholarship.pending, (state) => {
        state.error = [];
        state.isLoading = true;
      })
      .addCase(insertHoursScholarship.fulfilled, (state, action) => {
        state.error = [];
        state.isLoading = false;
      })
      .addCase(insertHoursScholarship.rejected, (state, action) => {
        state.error = {
          code: action.error.code,
          message: action.error.message,
        };
        state.isLoading = false;
      })
      .addCase(getPropety.pending, (state) => {
        state.error = [];
        state.isLoading = true;
      })
      .addCase(getPropety.fulfilled, (state, action) => {
        state.properties = action.payload.data;
        state.error = [];
        state.isLoading = false;
      })
      .addCase(getPropety.rejected, (state, action) => {
        state.error = {
          code: action.error.code,
          message: action.error.message,
        };
        state.isLoading = false;
      })
      .addCase(updateProperties.pending, (state) => {
        state.error = [];
        state.isLoading = true;
      })
      .addCase(updateProperties.fulfilled, (state, action) => {
        state.error = [];
        state.isLoading = false;
      })
      .addCase(updateProperties.rejected, (state, action) => {
        state.error = {
          code: action.error.code,
          message: action.error.message,
        };
        state.isLoading = false;
      });
  },
});

export const {
  filterScholarshipService,
  updateSemesterScholarshipServiceFilter,
} = scholarshipServiceSlice.actions;

export default scholarshipServiceSlice.reducer;
