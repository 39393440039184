import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

export default function BasicSelect({
  data,
  label,
  value,
  name,
  onChange,
  disabled
}) {


  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "100%" },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          id="outlined-select-currency"
          select
          label={label}
          value={value ? value : ""}
          helperText=""
          onChange={onChange}
          name={name}
          disabled={disabled}
          sx={{
            fontFamily: "Nunito, sans-serif",
            borderRadius: "100px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "100px",
            },
            "& .MuiInputLabel-root": {
              borderRadius: "100px",
            },
           
          }}
        >
          {data.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </Box>
  );
}
