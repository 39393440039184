import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import NavBar from "../../components/NavBar";
import BasicCard from "../../components/BasicCard";
import Grid from "@mui/material/Unstable_Grid2";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";

function Dashboard() {
  const history = useNavigate();
  let auth = useSelector((state) => state.auth);
  let type = null;
  const uuid = auth.uuid;

  if (auth.login === true) {
    type = JSON.parse(auth.type);
  }

  const goTo = async (data) => {
    history("/alumnos/" + data);
  };

  const tienePermisos = () => {
    if (type) {
      let totalPermisos = Object.values(type).length - 1;
      let permisosFalse = 0;
      Object.values(type).map((value) =>
        value === false ? permisosFalse++ : ""
      );
      return permisosFalse === totalPermisos ? false : true;
    }
    return false;
  };

  return (
    <Container>
      <NavBar />
      <Box>
        <Box sx={{ mb: 3 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 28, md: 36 },
              color: "#264496",
              fontFamily: "Nunito, sans-serif",
            }}
          >
            Servicios IMC
          </Typography>
          <Box sx={{ flexGrow: 1, mt: 5 }}>
            {tienePermisos() === false ? (
              <Box sx={{ textAlign: "center" }}>
                <Typography sx={{ color: "#e53935", fontSize: "20px" }}>
                  No tienes permisos, contacta al área de sistemas.
                </Typography>
              </Box>
            ) : null}
            <Grid container justifyContent="center" spacing={2}>
              {type?.pagos === true && (
                <Grid xs={12} md={3}>
                  <a onClick={() => goTo("pagos/" + uuid)}>
                    <BasicCard
                      title="Pagos"
                      description=""
                      icon={
                        <MonetizationOnIcon
                          color="#264496"
                          sx={{ my: 2, fontSize: 50 }}
                        />
                      }
                    ></BasicCard>
                  </a>
                </Grid>
              )}
              {type?.servicioBecario === true && (
                <Grid xs={12} md={3}>
                  <a onClick={() => goTo("servicio-becario/" + uuid)}>
                    <BasicCard
                      title="Servicio Becario"
                      description=""
                      icon={
                        <CastForEducationIcon sx={{ my: 2, fontSize: 50 }} />
                      }
                    ></BasicCard>
                  </a>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default Dashboard;
