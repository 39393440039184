import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import unorm from "unorm";

const initialState = {
  data: [
    {
      id: "",
      idExternalUser: "",
      name: "",
      secondName: "",
      lastName1: "",
      lastName2: "",
      sex: "",
      birthDate: "",
      curp: "",
      email: "",
      phone: "",
      placeBirth: "",
      nationatity: "",
      address: "",
      zip: "",
      type: "",
      pathPhoto: "",
      status: 0,
    },
  ],
  isLoading: false,
  filter: [],
  typeFilter: "",
  error: [
    {
      message: "",
      code: "",
    },
  ],
};

export const getGeneralDataUserActiveList = createAsyncThunk(
  "generalDataUsers/getGeneralDataUserActiveList",
  async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.get(
        process.env.REACT_APP_API_CORE_IMC +
          "/generalDataUsers/getAllActiveList",
        { headers: headers }
      );
      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const getGeneralDataUserByIdExternalUser = createAsyncThunk(
  "generalDataUsers/getGeneralDataUserByIdExternalUser",
  async (idExternalUser) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.get(
        process.env.REACT_APP_API_CORE_IMC +
          "/generalDataUsers/getByIdExternalUser/" +
          idExternalUser,
        { headers: headers }
      );
      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const upsert = createAsyncThunk(
  "generalDataUsers/upsert",
  async (formData) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_CORE_IMC + "/generalDataUsers/upsert",
        formData,
        config
      );
      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const getGeneralDataListByStatus = createAsyncThunk(
  "generalDataUsers/getGeneralDataListByStatus",
  async (status) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.get(
        process.env.REACT_APP_API_CORE_IMC +
          "/generalDataUsers/getGeneralDataListByStatus/"+status,
        { headers: headers }
      );
      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const generalDataUsersSlice = createSlice({
  name: "generalDataUsers",
  initialState: initialState,
  reducers: {
    filterGeneralDataUsers: (state, action) => {
      const { payload } = action;
      const { data, typeFilter } = state;

      // Función para normalizar y convertir a minúsculas
      const normalizeAndLowercase = (str) => unorm.nfkd(str).toLowerCase();
      if (payload !== "") {
        const filteredData = data.filter((generalDataUsers) => {
          const fullName = `${generalDataUsers.name} ${
            generalDataUsers.secondName || ""
          } ${generalDataUsers.lastName1} ${generalDataUsers.lastName2}`;
          return (
            (typeFilter === "" || generalDataUsers.type === typeFilter) &&
            (normalizeAndLowercase(generalDataUsers.name).includes(
              normalizeAndLowercase(payload)
            ) ||
              normalizeAndLowercase(generalDataUsers.lastName1).includes(
                normalizeAndLowercase(payload)
              ) ||
              normalizeAndLowercase(generalDataUsers.lastName2).includes(
                normalizeAndLowercase(payload)
              ) ||
              normalizeAndLowercase(
                generalDataUsers.name + " " + generalDataUsers.lastName1
              ).includes(normalizeAndLowercase(payload)) ||
              normalizeAndLowercase(
                generalDataUsers.name +
                  " " +
                  generalDataUsers.lastName1 +
                  " " +
                  generalDataUsers.lastName2
              ).includes(normalizeAndLowercase(payload)) ||
              normalizeAndLowercase(fullName).includes(
                normalizeAndLowercase(payload)
              ))
          );
        });

        state.filter = filteredData;
      } else {
        if (typeFilter === "" || typeFilter === "Todos") {
          state.filter = data;
        } else {
          if (data.length > 0) {
            let filteredData;
            if (typeFilter === "Docente") {
              filteredData = data.filter(
                (generalDataUsers) => generalDataUsers.type === typeFilter
              );
            } else {
              filteredData = data.filter(
                (generalDataUsers) => generalDataUsers.type !== "Docente"
              );
            }
            state.filter = filteredData;
          }
        }
      }
    },
    updateTypeFilter: (state, action) => {
      state.filterGeneralDataUsers = state.data;

      if (action.payload !== "" && action.payload !== "Todos") {
        const filtered = state.data.filter(function (el) {
          return action.payload.includes(el.type);
        });

        if (filtered) {
          state.filter = filtered;
        }
      } else {
        state.filter = state.data;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(upsert.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(upsert.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(upsert.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getGeneralDataUserByIdExternalUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getGeneralDataUserByIdExternalUser.fulfilled,
        (state, action) => {
          state.data = action.payload.data;
          state.error = {};
          state.isLoading = false;
        }
      )
      .addCase(getGeneralDataUserByIdExternalUser.rejected, (state, action) => {
        state.data = [];
        state.error = {
          code: action.error.code,
          message: action.error.message,
        };
        state.isLoading = false;
      })
      .addCase(getGeneralDataUserActiveList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGeneralDataUserActiveList.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.error = {};
        state.isLoading = false;
      })
      .addCase(getGeneralDataUserActiveList.rejected, (state, action) => {
        state.data = [];
        state.error = {
          code: action.error.code,
          message: action.error.message,
        };
        state.isLoading = false;
      })
      .addCase(getGeneralDataListByStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGeneralDataListByStatus.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.error = {};
        state.isLoading = false;
      })
      .addCase(getGeneralDataListByStatus.rejected, (state, action) => {
        state.data = [];
        state.error = {
          code: action.error.code,
          message: action.error.message,
        };
        state.isLoading = false;
      })
      ;
  },
});


export const { filterGeneralDataUsers, updateTypeFilter } =
  generalDataUsersSlice.actions;

export default generalDataUsersSlice.reducer;
