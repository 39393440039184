import {configureStore} from '@reduxjs/toolkit'
import tasksReducer from '../features/task/taskSlice'
import authReducer from '../features/auth/authSlice'
import studentsReducer from '../features/students/studentsSlice'
import paymentsReducer from '../features/payments/paymentsSlice'
import tutorsReducer from '../features/tutors/tutorsSlice'
import generalDataUsersReducer from '../features/generalDataUsers/generalDataUsersSlice'
import catalogsReducer from '../features/catalogs/catalogsSlice'
import scholarshipServiceReducer from '../features/scholarshipService/scholarshipServiceSlice'
import pdfsGeneratorReducer from '../features/pdfsGenerator/pdfsGeneratorSlice'
import birthdayReducer from '../features/birthday/birthdaySlice'


export const store = configureStore({
    reducer:{
        tasks: tasksReducer,
        auth: authReducer,
        students: studentsReducer,
        payments: paymentsReducer,
        tutors: tutorsReducer,
        generalDataUsers: generalDataUsersReducer,
        catalogs:catalogsReducer,
        scholarshipService:scholarshipServiceReducer,
        pdfsGenerator:pdfsGeneratorReducer,
        birthday:birthdayReducer
    }
    
})

