import React, { useEffect, useState } from "react";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export default function BasicDatePicker({
  label,
  onChangeDatePicker,
  name,
  defaultValue,
}) {

  const [value, setValue] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      setValue(moment(defaultValue, "DD-MM-YYYY"));
    }
  }, [defaultValue]);
  
  const handleChange = async (newValue) => {
    const date = await moment(newValue).format("DD-MM-YYYY");
    onChangeDatePicker({ date, name });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        format="DD-MM-YYYY"
        label={label}
        value={value}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} />}
        sx={{
          mx: 1,
          width: "100%",
        }}
      />
    </LocalizationProvider>
  );
}
